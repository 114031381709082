import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import SerialCodeFriday from '../../../Pages/SerialCodeFriday';


function CreateCodeFriday () {

    const [_amount , setAmount] = useState(1);
    const [_number , setNumber] = useState(1);
    const [_typeTime , setType] = useState();
    const [_member , setMember] = useState('');


    const RandomGenCode = (max , min) => {

        return Math.floor(Math.random() * (max - min + 1) + min)

    }


    const formData = async (e) => {

        e.preventDefault();
    

        const snapshot = await _.friday.doc('member_data').collection('friday_code').get();
        let duplipcate = [];

        snapshot.forEach((doc) => {

            const codeID = doc.id;
            duplipcate.push({codeID});

        });

        for(var i=0; i< _amount; i++) {

            var gen = RandomGenCode(100000 , 999999);
            const found = duplipcate.find(c => c === gen);
            found != null ? gen = RandomGenCode(100000 , 999999) : AddNewCode(gen);
        }

        CloseFormCreateData();

    }

    const AddNewCode = (_code) => {

        const _id =  `member_data` ;

        const today = new Date();
        const _time = today.toUTCString();
        const _firebaseTime = _.timeStamp;
        
        const _properties = {numberTime:parseInt(_number) , timeStamp:false , typeTime:_typeTime};
        const _option = {firebase_created:_firebaseTime};

        _.friday.doc(_id).collection('friday_code').doc(`${_code}`).set({

            code:_code,
            create:_time,
            status:true,
            expire:null,
            properties:_properties,
            member:_member,
            option:_option

        });
    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();
        
        var _form = document.getElementById('formCreateDataFriday');
        _form.style.top = '-100%';

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataFriday');
        _form.style.top = '-100%';


        refreshPage();

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<SerialCodeFriday/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataFriday' id='formCreateDataFriday'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >สร้างรหัสโค้ด Friday 2022</h1>
                    </div>
                    <div className='form-input'>
                        <span>จำนวนที่ต้องการคีย์</span>
                        <input className='form-control' id={'_amount'} type='number' value={_amount} onChange={(e) => setAmount(e.target.value)} placeholder='จำนวน' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>รหัสโค้ด</span>
                        <input className='form-control' id='_code' type='text'  placeholder='สุ่มอัตโนมัติ' readOnly></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>จำนวนระยะเวลา</span>
                        <input className='form-control' id={'_number'} type='number' value={_number} onChange={(e) => setNumber(e.target.value)} placeholder='ระยะเวลา' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>ประเภทของเวลา</span>
                        <select className='form-control' defaultValue={'DEFAULT'} value={_typeTime} onChange={(e) => setType(e.target.value)} required>
                            <option value='DEFAULT' disabled>เลือกประเภท</option>
                            <option value='minute'>นาที</option>
                            <option value='hours'>ชั่วโมง</option>
                            <option value='day'>วัน</option>
                        </select>
                    
                    </div>
                    <div className='form-input'>
                        <span>ผู้ใช้งาน</span>
                        <input className='form-control' id={'_member'} type='text' value={_member} onChange={(e) => setMember(e.target.value)} placeholder='ผู้ใช้งาน'></input>
                    
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default CreateCodeFriday;