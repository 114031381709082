/* eslint-disable jsx-a11y/scope */

import '../../../public/stylesheets/style-card.css';
import ReactDom from 'react-dom';
import MainPage from "../../../Pages/MainPage";
import StorePage from "../../../Pages/StoreProduct";
import _Product from "../../classcomponent/modelProducts";

const ItemProducts = (props) => {
    
    const updateData = async () => {

        const _modelProduct = new _Product.ModelProducts();

        _modelProduct.id = props.item.id;
        _modelProduct.name = props.item.name;
        _modelProduct.created = props.item.created;
        _modelProduct.status = props.item.status;
        _modelProduct.option.description = props.item.option.description;
        _modelProduct.option.price.discount = props.item.option.price.discount;
        _modelProduct.option.price.original = props.item.option.price.original;
        _modelProduct.option.sold = props.item.option.sold;
        _modelProduct.option.type = props.item.option.type;
        _modelProduct.images.id = props.item.images.id;
        _modelProduct.images.source = props.item.images.source;

        _Product.ListProducts.prototype.id = _modelProduct.id;
        _Product.ListProducts.prototype.name = _modelProduct.name;
        _Product.ListProducts.prototype.status = _modelProduct.status;
        _Product.ListProducts.prototype.description = _modelProduct.option.description;
        _Product.ListProducts.prototype.original = _modelProduct.option.price.original;
        _Product.ListProducts.prototype.discount = _modelProduct.option.price.discount;
        _Product.ListProducts.prototype.sold = _modelProduct.option.sold;
        _Product.ListProducts.prototype.type = _modelProduct.option.type;
        _Product.ListProducts.prototype.isUpdate = true;

        var _form = document.getElementById('formEditDataProduct');
        _form.style.top = '0';
       
        return(

            <div>
                {ReactDom.render(<MainPage pages={''}/> , document.getElementById('root'))}
                {ReactDom.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'))}
            </div>

        )

    }

    const delData = async () => {

        _Product.ModelProducts.prototype.id = props.item.id;
        _Product.ModelProducts.prototype.name = props.item.name;

        var _form = document.getElementById('formDelDataProduct');
        _form.style.top = '0';

        var _name = document.getElementById('_delname-p');
        _name.value = props.item.name;

    }
    return (

        <div className="listCard-product" id={`data${props.item.id}`}>

            <div className="card">
                <div className="img-offset img-product">
                    <img src={props.item.images.source} alt={props.item.images.id}></img>
                    <div className="shadow-img"></div>
                </div>
                <div className="container-card container-card-product">
                    <span className="sp-card pd-name">{`ชื่อสินค้า : ${props.item.name}`}</span>
                    <span className="sp-card pd-name">{`ลายละเอียด : ${props.item.option.description ? props.item.option.description : 'ไม่มี'}`}</span>
                    <span className={ props.item.status ? "sp-card text-status-on pd" : "sp-card text-status-off pd"}>{props.item.status ? 'สถานะ : เปิดใช้งาน' : 'สถานะ : ปิดใช้งาน'}</span>
                    <div className="description-product">
                        <div className="pd pd-type">
                            <span className="sp-card">{`ประเภท : ${props.item.option.type}`}</span>
                        </div>
                        <div className="pd pd-price">
                            <span className="sp-card">{`ราคา : ${props.item.option.price.original} ขันน้ำ`}</span>
                            <span className="sp-card">{`ส่วนลด : ${props.item.option.price.discount !== 0 ? props.item.option.price.discount : 'ไม่มี'}`}  </span>
                        </div>
                        <div className="pd pd-sold">
                            <span className="sp-card">{`แลกเปลี่ยนแล้ว : ${props.item.option.sold}`}</span>
                        </div>
                    </div>
                    <span className="sp-card">
                        <button className="btn text-white btnedit" id={`edit${props.item.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                        <button className="btn text-white btndel" id={`del${props.item.id}`} onClick={delData}>ลบข้อมูล</button>
                    </span>
                    
                </div>

            </div>
        </div>

    )
}

export default ItemProducts;