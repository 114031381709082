class ModelCodeFriday {

    code = null;
    status = null;
    expire = null;
    number = null;
    typeTime = null;
    member = null;

}


export default ModelCodeFriday;