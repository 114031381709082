import React, { useEffect, useState } from 'react'
import ItemHistory from '../components/items/history/itemHistory';
import ItemHistoryGacha from '../components/items/history/itemGacha';
import _ from '../utils/firebase';

function UsersHistory () {

    const [seletionHistory , setHistory] = useState('hisPayment');
    const [itemPayments , setItemPayment] = useState([]);
    const [itemGacha , setItemGacha] = useState([]);

    async function fetchData () {

        let listItem = [];

        const _hitUsers = localStorage.getItem('historyUsers');
        const _hitType = localStorage.getItem('historyType');
        const _id =  `AccountAssJarvis-24-Official` ;

        setHistory(_hitType);

        if(_hitUsers != null && _hitType === 'hisPayment') {

            tablePayment();

            const snapshot = await _.db.doc(_id).collection('Member_DB').doc(`${_hitUsers}`).get();
            snapshot.data().history_Payments.forEach((doc) => {

                const _idPayment = doc.id;
                const _idUsers = doc.users_id;
                const _dataPayment = doc.payments
                const data = {_idPayment , _idUsers , _dataPayment}
    
                listItem.push(data);
            });

            setItemPayment(listItem);

        } else if(_hitUsers != null && _hitType === 'hisGacha') {

            tableGacha();

            const snapshot = await _.db.doc(_id).collection('Member_DB').doc(`${_hitUsers}`).get();

            snapshot.data().history_Gacha.forEach((doc) => {

                const _idItem = doc.id;
                const _itemName = doc.name;
                const _dataOption = doc.option;
                const _dataTimeStamp = doc.timeStamp;
                const data = {_idItem , _itemName , _dataOption , _dataTimeStamp}
    
                listItem.push(data);
            });

            setItemGacha(listItem);

        } 


    }

    const tablePayment = () => {

        return(

            <table className="table" id='show-data'>
                <thead>
                    <tr>
                        <th scope="col"> ไอดีสินค้า</th>
                        <th scope="col"> ไอดีรหัสลูกค้า</th>
                        <th scope="col"> เวลาทำการแลกเปลี่ยน</th>
                        <th scope="col"> สินค้า</th>
                        <th scope="col"> ประเภทสินค้า</th>
                        <th scope="col"> ส่วนลด</th>
                        <th scope="col"> ราคาปกติ</th>
                        <th scope="col"> ราคารวม</th>
                        <th scope='col'> เช็คการได้รับ</th>
                    </tr>
                </thead>
                <tbody id="serialList">
                    {itemPayments.map((d) => (
                        <ItemHistory key={d.code} data={d} sel={seletionHistory}/>
                    ))}
                </tbody>
            </table>
        )
    }

    const tableGacha = () => {

        return(

            <table className="table" id='show-data'>
                <thead>
                    <tr>
                        <th scope="col"> ไอดีรางวัล</th>
                        <th scope="col"> ไอดีรหัสลูกค้า</th>
                        <th scope="col"> เวลาที่ได้รับ</th>
                        <th scope="col"> ระดับ</th>
                        <th scope="col"> ประเภทสินค้า</th>
                    </tr>
                </thead>
                <tbody id="serialList">
                    {itemGacha.map((d) => (
                        <ItemHistoryGacha key={d.code} data={d} sel={seletionHistory}/>
                    ))}
                </tbody>
            </table>
        )
    }

    const OpenFormSearchData = () => {

        var _form = document.getElementById('formSearchData');
        _form.style.top = '0';

        localStorage.setItem('historyType' , seletionHistory);
    }

    useEffect(() => {

        fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> ประวัติได้รับของรางวัล </span>
                <div>
                    <select className='form-control btn btncreate' id={'_selection'} value={seletionHistory} onChange={(e) => setHistory(e.target.value)}>
                        <option value={'default'} >เลือกประวัติ</option>
                        <option value={'hisGacha'}>ประวัติการเปิดของรางวัล</option>
                        <option value={'hisPayment'}>ประวัติการแลกเปลี่ยนของรางวัล</option>
                    </select>
                    <button className='btn btncreate' onClick={OpenFormSearchData}>ค้นหาประวัติ</button>
                </div>
            </div>

            <div className="table-data">
            {seletionHistory === 'hisPayment' ? tablePayment() : 
            seletionHistory === 'hisGacha' ? tableGacha() :
            console.log('not fine')}
            </div>
        </div>

    )

}


export default UsersHistory;