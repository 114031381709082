/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-serial.css';
import ModelServiceAccount from "../../classcomponent/modelServiceAccount";

const ItemService = (props) => {

    const updateData = async () => {


        ModelServiceAccount.prototype.id = props.data.id;
        ModelServiceAccount.prototype.status = props.data.properties.status;
        ModelServiceAccount.prototype.rolus = props.data.properties.value;
        ModelServiceAccount.prototype.member = props.data.properties.description;

        var _form = document.getElementById('formEditDataSeviceAccount');
        _form.style.top = '0';
       
        var _userId = document.getElementById('_edUserID-s'),
            _status = document.getElementById('_edstatus-s'),
            _edValue = document.getElementById('_edvalue-s'),
            _member = document.getElementById('_edmember-s');


        _userId.value = props.data.id;
        props.data.properties.status ? _status.value = 'true' : _status.value = 'false';
        props.data.properties.value === null ? _edValue.value  = '-' :_edValue.value = props.data.properties.value;
        props.data.properties.description === '' ? _member.value = '' : _member.value = props.data.properties.description;

    }

    const delData = async () => {

        ModelServiceAccount.prototype.id = props.data.id;
        ModelServiceAccount.prototype.status = props.data.properties.status;
        ModelServiceAccount.prototype.rolus = props.data.properties.value;
        ModelServiceAccount.prototype.member = props.data.properties.description;

        var _form = document.getElementById('formDelDataServiceAccount');
        _form.style.top = '0';

        var _userID = document.getElementById('_delUserID-s');
           
        _userID.value = props.data.id;

    }


    return (

        <tr id={`data${props.data.id}`} key={props.id}>
            <td id={`${props.data.id}-${props.data.create}`} scope="col">{props.data.create}</td>
            <td id={`${props.data.id}-${props.data.userID}`} scope="col">{props.data.userID}</td>
            <td id={`${props.data.id}-${props.data.password}`} scope="col">{props.data.password}</td>
            <td id={`${props.data.id}-${props.data.properties.value}`} scope="col">{props.data.properties.value}</td>
            <td id={`${props.data.id}-${props.data.properties.status}`} scope="col" className={props.data.properties.status ? 'text-status-on td-text' : 'text-status-off td-text'}>{props.data.properties.status ? 'เปิดให้บริการ' : 'ปิดให้บริการ'}</td>
            <td id={`${props.data.id}-${props.data.properties.description}`} scope="col">{props.data.properties.description}</td>
            <td>
                <div>
                    <button className="btn text-white btnedit" id={`edit${props.code}`} onClick={updateData}>แก้ไขข้อมูล</button>
                    <button className="btn text-white btndel" id={`del${props.code}`} onClick={delData}>ลบข้อมูล</button>
                </div>
            </td>
        </tr>
    )
}

export default ItemService;