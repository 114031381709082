export class ModelCodeSecretFeature {

    id = null;
    prefix = {
        name:null,
        code:null,
    };
    member = null;
    code = null;
    status = false;
    expire = null;
    typeTime = null;
    timestamp = null;
    description = null;

}

export class ModelCreateCode {
    id = null;
    prefix = {
        name:null,
        code:null,
    };
    member = null;
    code = null;
    status = true;
    timestamp = null;
    timeData = {
        timeExpire:null,
        timeLogin:null,
        option:{
            timeNumber:0,
            timeType:null,
            timeStartLogin:false,
        }

    };
    description = null;
    
}