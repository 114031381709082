/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-card.css';
import ReactDom from 'react-dom';
import AllGames from "../../../Pages/Games";
import ModelCompany from "../../classcomponent/modelCompany";
import MainPage from "../../../Pages/MainPage";

const ItemCard = (props) => {

    const updateData = async () => {


        ModelCompany.prototype.id = props.data.id;
        ModelCompany.prototype.name = props.data.name;
        ModelCompany.prototype.status = props.data.status;
        ModelCompany.prototype.path = props.data.properties.path;
        ModelCompany.prototype.height = props.data.properties.size.height;
        ModelCompany.prototype.width = props.data.properties.size.width;



        var _form = document.getElementById('formEditDataCompany');
        _form.style.top = '0';
       
        var _name = document.getElementById('_edname-c'),
            _status = document.getElementById('_edstatus-c'),
            _width = document.getElementById('_edwidth-c'),
            _height = document.getElementById('_edheight-c');


        _name.value = props.data.name;
        _width.value = props.data.properties.size.width;
        _height.value = props.data.properties.size.height;
        props.data.status ? _status.value = 'true' : _status.value = 'false';

    }

    const delData = async () => {

        ModelCompany.prototype.id = props.data.id;

        var _form = document.getElementById('formDelDataCompany');
        _form.style.top = '0';

        var _name = document.getElementById('_delname-c'),
            _status = document.getElementById('_delstatus-c');



        _name.value = props.data.name;
        props.data.status ? _status.value = 'เปิดใช้งาน' : _status.value = 'ปิดใช้งาน';

    }

    const OpenFormCreateData = () => {

        localStorage.setItem('company' , props.data.name);
        ReactDom.render(<MainPage pages={<AllGames/>}/> , document.getElementById('root'));

    }


    return (

        <div className="listCard" id={`data${props.data.id}`}>

            <div className="card">
                <img src={props.data.properties.path} alt={props.data.name} width={452} height={332}></img>
                <div className="container-card">
                    <span className="sp-card">{props.data.name}</span>
                    <span className={ props.data.status ? "sp-card text-status-on" : "sp-card text-status-off"}>{props.data.status ? 'สถานะ เปิดใช้งาน' : 'สถานะ ปิดใช้งาน'}</span>
                    <span className="sp-card">{`ขนาดรูปภาพ ${props.data.properties.size.width}x${props.data.properties.size.height}`}</span>
                    <span className="sp-card">
                        <button className="btn text-white btn-primary" id={`edit${props.data.id}`} onClick={OpenFormCreateData}>ดูเกมทั้งหมด</button>
                        <button className="btn text-white btnedit" id={`edit${props.data.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                        <button className="btn text-white btndel" id={`del${props.data.id}`} onClick={delData}>ลบข้อมูล</button>
                    </span>
                    
                </div>

            </div>

        </div>

    )
}

export default ItemCard;