import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import AllGames from '../../../Pages/Games';


function CreateFormGames () {

    const [_img , setImg] = useState('');
    const [_name , setGameName] = useState('');
    const [_company ,setCompany] = useState('');
    const [_status , setStatus] = useState('');

    const [_itemCompany , setItemCompany] = useState([]);
    const [following , setFollowing] = useState(true);

    const fachdata = async () => {

        try {

            setFollowing(false);

            const snapshot = await _.company.get();
            let listItem = [];

            snapshot.forEach((doc) => {

                const data = doc.data();

                listItem.push({data});

            });

        setItemCompany(listItem);

        } catch(e) {
            console.log(e.message);
        }

        

    }


    useEffect(() => {

        if(following) fachdata();

    })


    const formData = async (e) => {

        e.preventDefault();
        uploadFile();

    }

    const uploadFile = () => {

        _.storage.ref(`page/static/images/icon/${_company}/${_name}`).put(_img).then(()=>{
            loadFile();
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const loadFile = () => {

        _.storage.ref(`page/static/images/icon/${_company}/${_name}`).getDownloadURL().then(_url=>{
            const path = _url;
            AddNewGames(path);
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const AddNewGames = (_url) => {

        const today = new Date();
        const _time = today.toUTCString();
        const _path = `https://storage.googleapis.com/jarvis-24webservice.appspot.com/page/static/images/icon/${_company}/${_name}`;
        var _onstatus = null;
        const pathFile = _url;

        _status === 'true' ? _onstatus = true : _onstatus = false;

        _.games.add({

            game_name:_name,
            status:_onstatus,
            company_name:_company,
            imageFile:pathFile,
            path_ref:_path,
            obj_create:_time,

        });

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataGames');
        _form.style.top = '-100%';

        var _input_number = document.getElementById('_number'),
            _input_member = document.getElementById('_member'),
            _input_amount = document.getElementById('_amount');


        _input_amount.value = null;
        _input_number.value = null;
        _input_member.value = null;

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataGames');
        _form.style.top = '-100%';


        refreshPage();

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<AllGames/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataGames' id='formCreateDataGames'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >เพิ่มเกม</h1>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อค่ายเกม</span>
                        <select className='form-control' id={'_companyname'} type='text' value={_company} onChange={(e) => setCompany(e.target.value)}>
                            <option  value={'default'} >เลือกค่ายเกม</option>
                            {_itemCompany.map((d) => (
                                <option key={d.data.name} value={d.name}>{d.data.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อเกม</span>
                        <input className='form-control' id={'_gamename'} type='text' value={_name} onChange={(e) => setGameName(e.target.value)} placeholder='ชื่อเกม'></input>
                    </div>
                    <div className='form-input'>
                        <span>สถานะ</span>
                        <select className='form-control' id={'_gamestatus'} value={_status} onChange={(e) => setStatus(e.target.value)}>
                            <option value={'default'}>เลือกสถานะ</option>
                            <option value={'true'}>เปิดใช้งาน</option>
                            <option value={'false'}>ปิดใช้งาน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ไฟล์</span>
                        <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default CreateFormGames;