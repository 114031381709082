/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-card.css';
import EditCodeGames from "./itemEditGames";
import ModelGames from "../../classcomponent/modelGames";
import { useEffect, useState } from "react";
import DelGames from "./itemDelGames";
import CreateFormGames from "./itemCreateGame";

const ItemGames = (props) => {

    

    const updateData = async () => {


        ModelGames.prototype.id = props.data.id;
        ModelGames.prototype.name = props.data.game_name;
        ModelGames.prototype.company = props.data.company_name;
        ModelGames.prototype.status = props.data.status;
        ModelGames.prototype.path = props.data.path_ref;

        var _form = document.getElementById('formEditDataGames');
        _form.style.top = '0';
       
        var _name = document.getElementById('_edname-g'),
            _company = document.getElementById('_edcompany-g'),
            _status = document.getElementById('_edstatus-g');



        _name.value = props.data.game_name;
        _company.value = props.data.company_name;

        props.data.status ? _status.value = 'true' : _status.value = 'false';

    }

    const delData = async () => {

        ModelGames.prototype.id = props.data.id;
        ModelGames.prototype.name = props.data.game_name;
        ModelGames.prototype.company = props.data.company_name;
        ModelGames.prototype.status = props.data.status;
        ModelGames.prototype.path = props.data.path_ref;

        var _form = document.getElementById('formDelDataGames');
        _form.style.top = '0';

        var _name = document.getElementById('_delname-g'),
        _status = document.getElementById('_delstatus-g');



        _name.value = props.data.game_name;

        props.data.status ? _status.value = 'เปิดใช้งาน' : _status.value = 'ปิดใช้งาน';

    }


    return (

        <div className="listCard-game" id={`data${props.data.id}`}>
            <div className="card">
                <img src={props.data.path_ref} alt={props.data.game_name} width={props.data.company_name === 'AMB Slot' ? 130 : props.data.company_name === 'PGSoft' ? 170 : props.data.company_name === 'SlotXo' ? 150 : 200} height={170}></img>
                <div className="container-card">
                    <span className="sp-card-games">{props.data.game_name}</span>
                    <span className={props.data.status ? "sp-card-games text-status-on" : "sp-card-games text-status-off"}>
                        {props.data.status ? 'สถานะ เปิดใช้งาน' : 'สถานะ ปิดใช้งาน'}
                    </span>
                    <span className="sp-card-games">
                        <button className="btn text-white btnedit" id={`edit${props.data.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                        <button className="btn text-white btndel" id={`del${props.data.id}`} onClick={delData}>ลบข้อมูล</button>
                    </span>
                </div>
            </div>
            <CreateFormGames/>
            <EditCodeGames/>
            <DelGames/>
        </div>
    )
}

export default ItemGames;