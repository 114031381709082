import React, { useEffect, useState } from 'react'
import ItemService from '../components/items/serviceAccaount/itemService';
import _ from '../utils/firebase';

function ServiceAccount () {

    const [item , setItem] = useState([]);
    async function fetchData () {

        const _id =  `OrderCode` ;//localStorage.getItem('id');
        const snapshot = await _.db.orderBy('create' , 'desc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            if(doc.id !== _id) {

                const id = doc.id;
                const data = doc.data();

                listItem.push({id,...data});

            }
            
        });

        setItem(listItem);

    }

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataServiceAccount');
        _form.style.top = '0';

    }

    useEffect(() => {

        fetchData();
    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รายการผู้เช่าทั้งหมด </span>
                <button className='btn btncreate' onClick={OpenFormCreateData}>สร้างรหัสโค้ด</button>
            </div>

            <div className="table-data">
                
                <table className="table" id='show-data'>
                    <thead>
                        <tr>
                            <th scope="col"> ปี/เดือน/วัน - เวลา</th>
                            <th scope="col"> รหัส ID</th>
                            <th scope="col"> รหัส Password</th>
                            <th scope="col"> ช่องทางให้บริการ</th>
                            <th scope="col"> สถานะ</th>
                            <th scope="col"> หมายเหตุ</th>
                            <th scope="col"> จัดการ</th>
                        </tr>
                    </thead>
                    <tbody id="serialList">
                        {item.map((d) => (
                            <ItemService key={d.id} data={d}/>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    )

}


export default ServiceAccount;