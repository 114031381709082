import { useState } from "react";
import CreateCode from "../components/items/Jarvis/itemCreate";
import CreateFormCompany from "../components/items/company/itemCreateCompany";
import DelCode from "../components/items/Jarvis/itemDel";
import EditCode from "../components/items/Jarvis/itemEdit";
import SearchData from "../components/items/history/itemSearchHistory";
import MainSideBar from "../components/items/sidebar";
import CreateCodeFriday from "../components/items/friday/itemCreate";
import EditCodeFriday from "../components/items/friday/itemEdit";
import DelCodeFriday from "../components/items/friday/itemDel";
import EditCodeCompany from "../components/items/company/itemEdit";
import DelCompany from "../components/items/company/itemDel";
import CreateFormGames from "../components/items/games/itemCreateGame";
import EditCodeSeviceAccount from "../components/items/serviceAccaount/itemEdit";
import CreateServiceAccount from "../components/items/serviceAccaount/itemCreate";
import DelServiceAccount from "../components/items/serviceAccaount/itemDel";
import CreateFormProduct from "../components/items/store/itemCreateProduct";
import EditProduct from "../components/items/store/itemEditProduct";
import DelProduct from "../components/items/store/itemDelProduct";
import CreateFormCards from "../components/items/cards/itemCreateCards";
import EditCards from "../components/items/cards/itemEditCard";
import DelCards from "../components/items/cards/itemDelCards";
import ExchangeData from "../components/items/exchanges/exchangeToken";
import CreateCodeSecretFeature from "../components/items/secretfeature/itemCreate";
import EditCodeSecretFeature from "../components/items/secretfeature/itemEdit";
import DelCodeSecreFeature from "../components/items/secretfeature/itemDel";


function MainPage(props) {

    return (

        <div className="MianPage" id="_mainpage">

            <MainSideBar pages={props.pages}/>
            <CreateCodeSecretFeature/>
            <EditCodeSecretFeature/>
            <DelCodeSecreFeature/>
            <CreateCode/>
            <EditCode/>
            <DelCode/>
            <CreateFormCompany/>
            <SearchData/>
            <CreateCodeFriday/>
            <EditCodeFriday/>
            <DelCodeFriday/>
            <EditCodeCompany/>
            <DelCompany/>
            <CreateFormGames/>
            <EditCodeSeviceAccount/>
            <CreateServiceAccount/>
            <DelServiceAccount/>
            <CreateFormProduct/>
            <EditProduct/>
            <DelProduct/>
            <CreateFormCards/> 
            <EditCards/>
            <DelCards/>
            <ExchangeData/>
        </div>

    )

}

export default MainPage;