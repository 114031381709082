import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import _Cards from '../../classcomponent/modelsCards';
import CardsPage from '../../../Pages/CardsPage';


function EditCards () {
    
    const [_img , setImg] = useState([]);
    const [_name , setName] = useState('');
    const [_status , setStatus] = useState(true);
    const [_rate , setRate] = useState('N');
    const [_percent , setPercent] = useState(0);
    const [_type , setType] = useState('');
    const [_description , setDescription] = useState('');

    useEffect(() => {

        if(_Cards.ListCards.prototype.isUpdate) FachData();
    })


    const FachData = () => {

        setName(_Cards.ListCards.prototype.name);
        setStatus(_Cards.ListCards.prototype.status);
        setRate(_Cards.ListCards.prototype.rate);
        setDescription(_Cards.ListCards.prototype.description);
        setPercent(_Cards.ListCards.prototype.percent);
        setType(_Cards.ListCards.prototype.type);
        _Cards.ListCards.prototype.isUpdate = false;

    }


    const UpdateCompany = async (e) => {

        e.preventDefault();

        console.log(_Cards.ListCards.prototype);

        const _modelID = _Cards.ListCards.prototype.id;

        let _setStatus = null;

        switch (_status) {
            case true:
                _setStatus = true;
                break;
            case false:
                _setStatus = false;
                break;
            case 'true':
                _setStatus = true;
                break;
            case 'false':
            _setStatus = false;
            break;
            default:
                break;
        }

        if(_img === []) {


            await _.cardsDB.doc(_modelID).update({


                name:_name,
                status:_setStatus,
                option:{

                    description:_description,
                    rate:_rate,
                    percent:parseFloat(_percent),
                    type:_type,

                },

            });

        } else {

            await uploadFileEdit(_name);

            await _.cardsDB.doc(_modelID).update({

                name:_name,
                status:_setStatus,
                option:{
                    
                    description:_description,
                    rate:_rate,
                    percent:parseFloat(_percent),
                    type:_type,

                },

            });

        }
      
        CloseFormEditDataToRelode();
    }

    const uploadFileEdit = async (_name , _id) => {

        _.storage.ref(`page/static/images/events/Songkran/cards/${_name}.png`).put(_img).then(()=>{
            console.log('อัพโหลดเรียบร้อย');
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }


    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataCards');
        _form.style.top = '-100%';

        refreshPage();

        setImg([]);
        setName('');
        setStatus(true);
        setRate('N');
        setPercent(0);
        setType('');
        setDescription('');

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataCards');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<CardsPage/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataCards' id='formEditDataCards'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูลการ์ด</h1>
                </div>

                <div className='form-input'>
                    <span>การ์ด</span>
                    <input className='form-control' id={'_edname-c'} type='text'  value={_name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control'  id={'_edstatus-c'} value={_status}  onChange={(e) => setStatus(e.target.value)} >
                        <option value={'true'} >เปิดใช้งาน</option>
                        <option value={'false'}>ปิดใช้งาน</option>
                    </select>
                </div>
                <div className='form-input'>
                    <span>ระดับ</span>
                    <select className='form-control'  id={'_edstatus-c'} value={_rate}  onChange={(e) => setRate(e.target.value)} >
                        <option value={'N'}>ไม่มี</option>
                        <option value={'R'} >ทั่วไป</option>
                        <option value={'SR'}>ยาก</option>
                        <option value={'SSR'}>หายาก</option>
                    </select>
                </div>
                <div className='form-input'>
                        <span>ประเภทของสุ่ม</span>
                        <select className='form-control' id={'_type'} value={_type} onChange={(e) => setType(e.target.value)}>
                            <option value={'card'}>การ์ดสะสม</option>
                            <option value={'time'}>เพิ่มเวลา</option>
                            <option value={'bowl'}>ขันน้ำ</option>
                        </select>
                    </div>
                <div className='form-input'>
                        <span>เปอร์เซ็นออก</span>
                        <input className='form-control' id={'_percent'} type='number' value={_percent} onChange={(e) => setPercent(e.target.value)} placeholder={'เปอร์เซ็นการสุ่มออก'}></input>
                    </div>
                <div className='form-input'>
                    <span>ลายละเอียด</span>
                    <input className='form-control' id={'_edname-c'} type='text'  value={_description} onChange={(e) => setDescription(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>อัพโหลดรูปภาพ</span>
                    <input className='form-control input-file' id={'_edfiles-c'} type='file' onChange={(e) => setImg(e.target.files[0])}></input>
                   
                </div>
                
                
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={UpdateCompany}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCards;