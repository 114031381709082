class ModelGames {

    id = null;
    name = null;
    company = null;
    status = null;
    path = null;
}


export default ModelGames;