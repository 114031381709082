class ModelProducts {

    id = null;
    name = null;
    status = null;
    option = {
                description:null,
                price:{
                    discount:0,
                    original:0
                },
                sold:0,
                type:null

            };
    images = {

        id:null,
        source:null

    };
    created = null;

}


class ListProducts {

    id = null;
    name = null;
    status = null;
    description = null;
    discount = 0;
    original = 0;
    sold = 0;
    type = null;
    imagesId = null;
    source = null;
    created = null;

    isUpdate = false;

}

const _Product = {

    ModelProducts,
    ListProducts

}
export default _Product;