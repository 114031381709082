import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCompany from '../../classcomponent/modelCompany';

function DelCompany () {
    
    const delData = async () => {


        const _modelID = ModelCompany.prototype.id;
        console.log(_modelID);

        const query = document.querySelector(`[id=data${_modelID}]`);
        console.log(query);
        query.remove();
        await _.company.doc(_modelID).delete();

        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataCompany');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelDataCompany' id='formDelDataCompany'>
            <div className='box-data-search'>

                <div className='text-white'>
                    <h1 >ลบค่ายเกม</h1>
                </div>

                <div className='form-input'>
                    <span>ค่ายเกม</span>
                    <input className='form-control' id={'_delname-c'} type='text' readOnly></input>
                </div>

                <div className='form-input'>
                    <span>สถานะ</span>
                    <input className='form-control' id={'_delstatus-c'} type='text'  readOnly></input>
                </div>

                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelCompany;