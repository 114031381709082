/* eslint-disable jsx-a11y/scope */

import '../../../public/stylesheets/style-serial.css';
import _ from '../../../utils/firebase';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import UsersHistory from '../../../Pages/UserHistory';

const ItemHistoryPayments = (props) => {

    const ConfirmUpdatePayment = async () => {

        const _id =  `AccountAssJarvis-24-Official` ;
        const _hitUsers = localStorage.getItem('historyUsers');
        const snapshot = await _.db.doc(_id).collection('Member_DB').doc(`${_hitUsers}`).get();
        let payArray = snapshot.data().history_Payments;

        payArray.forEach((doc) => {
            if(doc.id === props.data._idPayment) {
                doc.payments.order_confirm = true;
            }
        });


        await _.db.doc(_id).collection('Member_DB').doc(`${_hitUsers}`).update({
            history_Payments:payArray
        });


        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<UsersHistory/>}/> , document.getElementById('root'));
            
        },500);

    }

    const AddButtonConfirm = () => {

        return (
            <button className='btn btnedit text-white' onClick={ConfirmUpdatePayment} type='submit' >ยืนยันการได้รับ</button>
        )
    }

    const PassConfirm = () => {

        return (
            <div className='text-white input-file'>{'ได้รับเรียบร้อยแล้ว'}</div>
        )
    }

    return (

        <tr id={`data${props.data._idPayment}`} key={props.data._idPayment}>
                
                <td scope="col">{props.data._idPayment}</td>
                <td scope="col">{props.data._idUsers}</td>
                <td scope="col">{props.data._dataPayment.order_date}</td>
                <td scope="col">{props.data._dataPayment.order_Name}</td>
                <td scope="col">{props.data._dataPayment.order_type}</td>
                <td scope="col">{props.data._dataPayment.order_price.discount}</td>
                <td scope="col">{props.data._dataPayment.order_price.original}</td>
                <td scope="col">{props.data._dataPayment.order_price.total}</td>
                <td scope='col'>
                    {props.data._dataPayment.order_confirm ? PassConfirm() : AddButtonConfirm()}
                </td>
        </tr>
    )
}

export default ItemHistoryPayments;