import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCode, { ModelCodeJarvis } from '../../classcomponent/modelCode';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import SerialCode from '../../../Pages/SerialCode';

function EditCode () {
    
    const [_member , setMember] = useState('');
    const [_description , setDescription] = useState('');

    const UpdateCode = async (e) => {
        
        e.preventDefault();

        const id = ModelCodeJarvis.prototype.id;

        let _modelMember = null;

        _member === undefined ? _modelMember = ModelCodeJarvis.prototype.member : _modelMember = _member;

        await _.jarvis_code.doc(id).update({
            // member:_modelMember,
            description:_description
        });

        CloseFormEditDataToRelode();
    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataJarvis');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataJarvis');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<SerialCode/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditData' id='formEditDataJarvis'>
            <div className='box-data'>
                <div className='text-white'>
                    <h1 >แก้ไขข้อมูล Jarvis</h1>
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_edmemberJ'} type='text' readOnly></input>
                </div>
                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_edcodeJ'} type='number' readOnly></input>
                </div>
                 <div className='form-input'>
                    <span>สถานะการใช้งาน</span>
                    <input className='form-control' id={'_edstatusJ'} type='text'  readOnly></input>
                </div>
                <div className='form-input'>
                    <span>เวลาหมดอายุ</span>
                    <input className='form-control' id={'_edexpireJ'} type='text'  readOnly></input>
                </div>
                <div className='form-input'>
                        <span>หมายเหตุ</span>
                        <input className='form-control' id={'_edescriptionJ'} type='text' onChange={(e) => setDescription(e.target.value)}></input>
                </div>
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateCode}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCode;