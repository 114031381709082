import React, { useEffect, useState } from 'react'
import ItemObj from '../components/items/Jarvis/itemCode';
import _ from '../utils/firebase';
import { ModelCodeJarvis } from '../components/classcomponent/modelCode';

function SerialCode () {

    const [item , setItem] = useState([]);
    const [_isReq , setReq] = useState(true);

    async function fetchData () {

        setReq(false);

        try {

            // const _id =  `AccountAssJarvis-24-Official` ;
            // const snapshot = await _.db.doc(_id).collection('member_data').orderBy('option.firebase_created' , 'desc').get();
            const snapshot = await _.jarvis_code.orderBy('timestamp' , 'desc').get();
            let listItem = [];

            snapshot.forEach((doc) => {
                // var compareExpireDate = null;
                
                // if(doc.data().expire != null) {
                //     var userExpireDate =  new Date(doc.data().expire);
                //     compareExpireDate = `${userExpireDate.getDate()}/${userExpireDate.getMonth()}/${userExpireDate.getFullYear()} ${userExpireDate.getHours()}:${userExpireDate.getMinutes()}:${userExpireDate.getSeconds()}`;
                // } else {
                //     compareExpireDate = null;
                // }

                // if(doc.data().expire != null) {

                //     const today = new Date();
                //     const userDate = new Date(doc.data().expire);
                //     if(today > userDate) {
                //         DeleteUserCode(_id , doc.id);
                //     }

                // } else {

                //     const today = new Date();
                //     const userCreate = new Date(doc.data().create);
                //     today.setDate(today.getDate() - 2);

                //     if(today > userCreate) {
                //         DeleteUserCode(_id , doc.id);
                //     }

                // }

                const id = doc.id;
                const data = doc.data();

                // listItem.push({id,...data , compareExpireDate});

                listItem.push({id,...data});

            });

            setItem(listItem);
            return;

        } catch(e) {

            console.log(e.message);

        }
    }

    // const DeleteUserCode = (_id ,_codeID) => {

    //     _.db.doc(_id).collection('member_data').doc(_codeID).delete();
    //     _.db.doc('OrderCode').collection('ListCodeData').doc(_codeID).delete();

    // }

    const OnmodelCode = (data) => {
        const model = new ModelCodeJarvis();
        model.id = data.id
        model.prefix = data.prefix;
        model.code = data.code;
        model.member = data.member;
        model.expire = data.timeData.timeExpire;
        model.status = data.status;
        model.timestamp = data.timestamp;
        model.description = data.description;
        
        return model;
    }

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataJarvis');
        _form.style.top = '0';

    }

    useEffect(() => {

        if(_isReq) fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รายการโค้ดทั้งหมด Jarvis 2024 </span>
                <button className='btn btncreate' onClick={OpenFormCreateData}>สร้างรหัสโค้ด</button>
            </div>

            <div className="table-data">
                
                <table className="table" id='show-data'>
                    <thead>
                        <tr>
                            <th scope="col"> วัน/เดือน/ปี - เวลา</th>
                            <th scope="col"> ยูสเซอร์</th>
                            <th scope="col"> รหัสโค้ด</th>
                            <th scope="col"> สถานะ</th>
                            <th scope="col"> เวลาหมดอายุ</th>
                            <th scope="col"> หมายเหตุ</th>
                            <th scope="col"> จัดการ</th>
                        </tr>
                    </thead>
                    <tbody id="serialList">
                        {item.map((d) => (
                            <ItemObj {...OnmodelCode(d)}/>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    )

}


export default SerialCode;