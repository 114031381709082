import './App.css';
import LoginPage from './Pages/Login';
import { useEffect } from 'react';
import _ from './utils/firebase';
import ReactDOM from 'react-dom';
import MainPage from './Pages/MainPage';
// import SerialCode from './Pages/SerialCode';
import SerialCodeFeature from './Pages/SerialCodeFeatureCode';


function App() {

  useEffect(() => {

    _.auth.onAuthStateChanged(user => {
      if(user) {
          ReactDOM.render(<MainPage pages={<SerialCodeFeature/>}/> , document.getElementById('root'));
          return
      } else {
          ReactDOM.render(<LoginPage/> , document.getElementById('root'));
          return
      }

    })
  });
  
  return (
    <div className="App">

    </div>
  );
}

export default App;
