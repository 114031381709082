class ModelCompany {

    id = null;
    name = null;
    status = null;
    path = null;
    width = null;
    height = null;

}


export default ModelCompany;