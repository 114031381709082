class ModelCards {

    id = null;
    name = null;
    status = false;
    option = {
                description:null,
                rate:'R',
                percent:0 ,
                type:null

            };
    images = {

        id:null,
        source:null

    };
    created = null;

}
class ListCards {

    id = null;
    name = null;
    status = false;
    description = null;
    rate = 'R';
    percent = 0;
    type = null;

    isUpdate = false;

}

const _Cards = {

    ModelCards,
    ListCards

}

export default _Cards;