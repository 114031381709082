/* eslint-disable jsx-a11y/scope */

import '../../../public/stylesheets/style-serial.css';

const ItemHistoryGacha = (props) => {

    
    return (

        <tr id={`data${props.data._idItem}`} key={props.data._idItem}>
                <td scope="col">{props.data._idItem}</td>
                <td scope="col">{props.data._itemName}</td>
                <td scope="col">{props.data._dataTimeStamp}</td>
                <td scope="col">{props.data._dataOption.rate}</td>
                <td scope="col">{props.data._dataOption.type}</td>
        </tr>
    )
}

export default ItemHistoryGacha;