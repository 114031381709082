import React, { useEffect, useState } from 'react'
import ItemObjFriday from '../components/items/friday/itemCode';
import _ from '../utils/firebase';

function SerialCodeFriday () {

    const [item , setItem] = useState([]);
    const [_isReq , setReq] = useState(true);
    async function fetchData () {

        setReq(false);
        const snapshot = await _.friday_code.doc('member_data').collection('friday_code').orderBy('create' , 'desc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            if(doc.data().expire != null) {

                const today = new Date();
                const userDate = new Date(doc.data().expire);
                if(today > userDate) {
                    // DeleteUserCode(doc.id);
                }

            } else {

                const today = new Date();
                const userCreate = new Date(doc.data().create);
                today.setDate(today.getDate() - 2);

                // if(today > userCreate) {

                //     console.log(doc.id);
                // }

            }

            const id = doc.id;
            const data = doc.data();

            listItem.push({id,...data});
        });

        setItem(listItem);

    }

    // const DeleteUserCode = (_codeID) => {

    //     _.friday.doc('member_data').collection('friday_code').doc(_codeID).delete();

    // }

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataFriday');
        _form.style.top = '0';

    }

    useEffect(() => {

        if(_isReq) fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รายการโค้ดทั้งหมด Friday 2023 </span>
                <button className='btn btncreate' onClick={OpenFormCreateData}>สร้างรหัสโค้ด</button>
            </div>

            <div className="table-data">
                
                <table className="table" id='show-data'>
                    <thead>
                        <tr>
                            <th scope="col"> ปี/เดือน/วัน - เวลา</th>
                            <th scope="col"> รหัสโค้ด</th>
                            <th scope="col"> สถานะ</th>
                            <th scope="col"> เวลาหมดอายุ</th>
                            <th scope="col"> หมายเหตุ</th>
                            <th scope="col"> จัดการ</th>
                        </tr>
                    </thead>
                    <tbody id="serialList">
                        {item.map((d) => (
                            <ItemObjFriday key={d.id} data={d} crete={d.create} code={d.code} status={d.status} expire={d.expire} member={d.member}/>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    )

}


export default SerialCodeFriday;