import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCodeFriday from '../../classcomponent/modelCodeFriday';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import SerialCodeFriday from '../../../Pages/SerialCodeFriday';

function EditCodeFriday () {
    
    const [_member , setMember] = useState();

    const UpdateCode = async (e) => {
        
        e.preventDefault();

        const _id =  `member_data`;
        const _modelCode = ModelCodeFriday.prototype.code;
        let _modelMember = null;

        _member === undefined ? _modelMember = ModelCodeFriday.prototype.member : _modelMember = _member;

        await _.friday.doc(_id).collection('friday_code').doc(`${_modelCode}`).update({
            member:_modelMember
        });

        CloseFormEditDataToRelode();
    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataFriday');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataFriday');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<SerialCodeFriday/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataFriday' id='formEditDataFriday'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูล</h1>
                </div>

                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_edcode-f'} type='number' readOnly></input>
                </div>
                 <div className='form-input'>
                    <span>สถานะการใช้งาน</span>
                    <input className='form-control' id={'_edstatus-f'} type='text'  readOnly></input>
                   
                </div>
                <div className='form-input'>
                    <span>เวลาหมดอายุ</span>
                    <input className='form-control' id={'_edexpire-f'} type='text'  readOnly></input>
                   
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_edmember-f'} type='text' onChange={(e) => setMember(e.target.value)}></input>
                   
                </div>
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateCode}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCodeFriday;