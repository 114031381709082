import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCode, { ModelCodeJarvis } from '../../classcomponent/modelCode';

function DelCode () {
    
    const delData = async () => {

        const id = ModelCodeJarvis.prototype.id;

        const query = document.querySelector(`[id=data${id}]`);
        query.remove();

        await _.jarvis_code.doc(id).delete();

        CloseFormDelData();
    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataJarvis');
        _form.style.top = '-100%';
    }

    return(

        <div className='formDelData' id='formDelDataJarvis'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >ลบรหัสโค้ด Jarvis</h1>
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_delmemberJ'} type='text' ></input>
                </div>
                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_delcodeJ'} type='number' readOnly></input>
                </div>
                <div className='form-input'>
                    <span>เวลาหมดอายุ</span>
                    <input className='form-control' id={'_delexpireJ'} type='text'  readOnly></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelCode;