import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelGames from '../../classcomponent/modelGames';

function DelGames () {
    
    const delData = async () => {


        const _modelID = ModelGames.prototype.id;
        console.log(_modelID);

        const query = document.querySelector(`[id=data${_modelID}]`);
        query.remove();
        await _.games.doc(_modelID).delete();

        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataGames');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelDataGames' id='formDelDataGames'>
            <div className='box-data-search'>

                <div className='text-white'>
                    <h1 >ลบค่ายเกม</h1>
                </div>

                <div className='form-input'>
                    <span>เกม</span>
                    <input className='form-control' id={'_delname-g'} type='text' readOnly></input>
                </div>

                <div className='form-input'>
                    <span>สถานะ</span>
                    <input className='form-control' id={'_delstatus-g'} type='text'  readOnly></input>
                </div>

                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelGames;