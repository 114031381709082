import React, { useEffect, useState } from 'react'
import ItemProducts from '../components/items/store/itemProduct';
import _ from '../utils/firebase';
import ReactDom from 'react-dom';
import MainPage from './MainPage';
import CardsPage from './CardsPage';

function StorePage () {

    const [item , setItem] = useState([]);
    async function fetchData () {

        const snapshot = await _.products.orderBy('created' , 'desc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            const id = doc.id;
            const data = doc.data();

            listItem.push({id,...data});
        });

        setItem(listItem);

    }

    const OpenFormCreateDataProduct = () => {
        var _form = document.getElementById('formCreateDataProduct');
        _form.style.top = '0';
    }

    const ExchangeToken = () => {
        var _form = document.getElementById('formEditExchanheData');
        _form.style.top = '0';
    }

    const ViewCardsCollection = () => {

        ReactDom.render(<MainPage pages={<CardsPage/>}/> , document.getElementById('root'))

    }

    useEffect(() => {

        fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รายการสินค้า </span>
                <div className='group-btn'>
                    <button className='btn btncreate btn-games' onClick={OpenFormCreateDataProduct}>เพิ่มสินค้า</button>
                    <button className='btn btncreate btn-games' onClick={ExchangeToken}>อัตราการแลกเปลี่ยน</button>
                    <button className='btn btncreate btn-games' onClick={ViewCardsCollection}>การ์ดสะสม / สุ่มรางวัล</button>
                </div>
            </div>
            <div className="list-container-card product">
                {item.map((d) => (
                  <ItemProducts key={d.id} item={d}/>
                ))}
            </div>
        </div>

    )

}


export default StorePage;