import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import CompanyGame from '../../../Pages/CompanyGame';


function CreateFormCompany () {

    const [_img , setImg] = useState('');
    const [_name , setName] = useState('');

    const formData = async (e) => {

        e.preventDefault();
    
        uploadFile();

    }

    const uploadFile = () => {

        _.storage.ref(`page/static/images/company/${_name}`).put(_img).then(()=>{
            AddNewCode();
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const AddNewCode = () => {

        const today = new Date();
        const _time = today.toUTCString();
        const _path = `https://storage.googleapis.com/jarvis-24webservice.appspot.com/page/static/images/company/${_name}`;
        
        const _size = {height:80 , width:170};
        const _properties = {path:_path , size:_size};

        _.company.add({

            name:_name,
            create:_time,
            status:false,
            properties:_properties,

        });

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataCompany');
        _form.style.top = '-100%';

        var _input_number = document.getElementById('_number'),
            _input_member = document.getElementById('_member'),
            _input_amount = document.getElementById('_amount');


        _input_amount.value = null;
        _input_number.value = null;
        _input_member.value = null;

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataCompany');
        _form.style.top = '-100%';

        var _input_number = document.getElementById('_number'),
            _input_member = document.getElementById('_member'),
            _input_amount = document.getElementById('_amount');


        _input_amount.value = null;
        _input_number.value = null;
        _input_member.value = null;

        refreshPage();

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<CompanyGame/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataCompany' id='formCreateDataCompany'>

                <div className='box-data-search'>
                    <div className='text-white'>
                        <h1 >เพิ่มค่ายเกม</h1>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อค่ายเกม</span>
                        <input className='form-control' id={'_name'} type='text' value={_name} onChange={(e) => setName(e.target.value)} placeholder='ค่ายเกม'></input>
                    </div>
                    <div className='form-input'>
                        <span>ไฟล์</span>
                        <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default CreateFormCompany;