import React, { useEffect, useState } from 'react'
import ReactDOM  from 'react-dom';
import _ from '../utils/firebase';
import MainPage from './MainPage';
import '../public/stylesheets/style-login.css';
// import SerialCode from './SerialCode';
import SerialCodeFeature from './SerialCodeFeatureCode';



const LoginPage = () => {

    const [id , setID] = useState('');
    const [password , setPassword] = useState('');


    const FirebaseAuthLogin = (e) => {

        e.preventDefault();
        
        if(id === undefined && password === undefined ||
            id === undefined && password !== undefined ||
            id !== undefined && password === undefined) {

            window.alert(`โปรดกรอกรหัสให้ครบถ้วน`);

        } else {

            _.auth.signInWithEmailAndPassword(id , password)
            .then((user) => {
                ReactDOM.render(<MainPage pages={<SerialCodeFeature/>}/> , document.getElementById('root'));
            })
            .catch((err) => {
                window.alert(`กรอกรหัสไม่ถูกต้อง`);
            })
        }

    }

    return(

        <div className='LoginForm'> 
            <form className='form-SignIn'>
                <div className='Login-Container box-data-login'>
                    <div className='text-header-login'>
                        <h1>ล็อคอินเข้าสู่ระบบ</h1>
                    </div>
                    <div className=''>
                        <div className='form-input-login'>
                            <div className='input-form text-white'>
                                <span>E-mail</span>
                                <input className='form-control input-SignIn ' id='_uid' type='text' placeholder='exsample@gmail.com' value={id} onChange={(e) => setID(e.target.value)}></input>
                            </div>
                        </div>
                        <br></br>
                        <div className='form-input-login'>
                            <div className='input-form text-white'>
                                <input className='form-control input-SignIn' id='_upassword' type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div className='form-input-login login-descripts'>
                        <h5>เข้าสู่ระบบเพิ่อการจัดการข้อมูลภายในของลูกค้า</h5>
                    </div>          
                    <div className='form-input-login-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate btn-Login' type='submit' onClick={FirebaseAuthLogin}>เข้าสู่ระบบ</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}


export default LoginPage;
