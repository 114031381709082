/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-serial.css';
import { ModelCodeSecretFeature } from "../../classcomponent/modelCodeSecretFeature";

const ItemObj = (props) => {

    const updateData = async () => {
        ModelCodeSecretFeature.prototype.id = props.id;
        ModelCodeSecretFeature.prototype.member = props.member;
        ModelCodeSecretFeature.prototype.description = props.description;

        var _form = document.getElementById('formEditDataSecreFeature');
        _form.style.top = '0';
       
        var _code = document.getElementById('_edcode'),
            _status = document.getElementById('_edstatus'),
            _expire = document.getElementById('_edexpire'),
            _member = document.getElementById('_edmember'),
            _description = document.getElementById('_edescription');

        _code.value = props.code;
        props.description === null ? _description.value = '-' : _description.value = props.description;
        props.status ? _status.value = 'ใช้งานได้' : _status.value = 'ไม่สามารถใช้งานได้';
        props.expire === null ? _expire.value  = '-' :_expire.value = props.expire;
        props.member === '' ? _member.value = 'ไม่พบรหัส' : _member.value = `${props.prefix.name}${props.member}`;
    }

    const delData = async () => {

        ModelCodeSecretFeature.prototype.id = props.id;
        ModelCodeSecretFeature.prototype.member = props.member;

        var _form = document.getElementById('formDelDataSecreFeature');
        _form.style.top = '0';

        var _code = document.getElementById('_delcode'),
            _expire = document.getElementById('_delexpire'),
            _member = document.getElementById('_delmember');

        _code.value = props.code;
        props.expire === null ? _expire.value  = '-' :_expire.value = props.expire;
        props.member === '' ? _member.value = '-' : _member.value = `${props.prefix.name}${props.member}`;

    }

    return (

        <tr id={`data${props.id}`} key={props.id}>
            <td scope="col">{`${(new Date(props.timestamp.seconds*1000).toLocaleString())}`}</td>
            <td scope="col">{props.member === null || props.member === '' ? 'ไม่พบรหัส' : `${props.prefix.name}${props.member}`}</td>
            <td scope="col">{props.code}</td>
            <td scope="col" className={ props.status ? "text-status-on" : "text-status-off"}>{props.status ? 'ใช้งานได้' : 'หมดอายุ'}</td>
            <td scope="col">{props.expire === null ? '-' : props.expire }</td>
            <td scope="col">{props.description === null ? '-' : props.description}</td>
            <td>
                <div>
                    <button className="btn text-white btnedit" id={`edit${props.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                    <button className="btn text-white btndel" id={`del${props.id}`} onClick={delData}>ลบข้อมูล</button>
                </div>
            </td>
        </tr>
    )
}

export default ItemObj;