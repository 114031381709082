import React, { useEffect, useState } from 'react';
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import UsersHistory from '../../../Pages/UserHistory';

function SearchData () {

    const [users , setUsers] = useState('');

    const OnClickFormSearchData = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formSearchData');
        _form.style.top = '-100%';

        localStorage.setItem('historyUsers' , users);
        refreshPage();
    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<UsersHistory/>}/> , document.getElementById('root'));
            
        },500);

    }


    const CloseFormSearchData = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formSearchData');
        _form.style.top = '-100%';
    }


    return(

        <form>

            <div className='formSearchData' id='formSearchData'>

                <div className='box-data-search'>
                    <div className='text-white'>
                        <h1 >ค้นหาประวัติ</h1>
                    </div>
                    
                    <div className='form-input'>
                        <span>ผู้ใช้งาน</span>
                        <input className='form-control' id={'_member'} type='text' value={users} onChange={(e) => setUsers(e.target.value)}  placeholder='ผู้ใช้งาน'></input>
                    
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={OnClickFormSearchData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormSearchData}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default SearchData;