import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import StorePage from '../../../Pages/StoreProduct';
import _Product from '../../classcomponent/modelProducts';

function CreateFormProduct () {

    const [_img , setImg] = useState('');
    const [_name , setName] = useState('');
    const [_status , setStatus] = useState(true);
    const [_type , setType] = useState('ticket');
    const [_price , setPrice] = useState(0);
    const [_discount , setDiscount] = useState(0);
    const [_description , setDescription] = useState('');


    const formData = async (e) => {

        e.preventDefault();
    
        uploadFile();
    }

    const uploadFile = () => {

        _.storage.ref(`page/static/images/events/Songkran/${_type}/${_name}`).put(_img).then(()=>{
            AddNewCode();
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const AddNewCode = () => {

        const _path = `https://storage.googleapis.com/jarvis-24webservice.appspot.com/page/static/images/events/Songkran/${_type}/${_name}`;
      
        let _modelProduct = new _Product.ModelProducts();

        _modelProduct.name = _name;
        _modelProduct.status = _status ? true : false;
        _modelProduct.option.description = _description;
        _modelProduct.option.price.discount = parseInt(_discount);
        _modelProduct.option.price.original = parseInt(_price);
        _modelProduct.option.sold = 0;
        _modelProduct.option.type = _type;
        _modelProduct.images.id = null;
        _modelProduct.images.source = _path;
        _modelProduct.created = _.timeStamp;

        _.products.add({

            name:_modelProduct.name,
            status:_modelProduct.status,
            option:{

                    description:_modelProduct.option.description,
                    price:{

                        discount:_modelProduct.option.price.discount,
                        original:_modelProduct.option.price.original
                    },
                    sold:0,
                    type:_modelProduct.option.type

            },
            images:{

                id:null,
                source:_modelProduct.images.source

            },
            created:_.timeStamp

        });

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataProduct');
        _form.style.top = '-100%';

        ClearUseState();

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataProduct');
        _form.style.top = '-100%';

        
        ClearUseState();
        refreshPage();

    }

    const ClearUseState = () => {

        setImg('')
        setName('');
        setStatus(true);
        setType('ticket');
        setPrice(0);
        setDiscount(0);
        setDescription('');

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataProduct' id='formCreateDataProduct'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >เพิ่มสินค้า</h1>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อสินค้า</span>
                        <input className='form-control' id={'_name'} type='text' value={_name} onChange={(e) => setName(e.target.value)} placeholder={'ชื่อสินค้า'}></input>
                    </div>
                    <div className='form-input'>
                        <span>สถานะ</span>
                        <select className='form-control' defaultValue={true} id={'_status'}  value={_status} onChange={(e) => setStatus(e.target.value)}>
                            <option value={true}>เปิดใช้งาน</option>
                            <option value={false}>ปิดใช้งาน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ประเภท</span>
                        <select className='form-control' id={'_type'} value={_type} onChange={(e) => setType(e.target.value)}>
                            <option value={'ticket'}>ตั๋วแลกโค้ด</option>
                            <option value={'time'}>เพิ่มเวลาการใช้งาน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ราคา</span>
                        <input className='form-control' id={'_price'} type='number' value={_price} onChange={(e) => setPrice(e.target.value)} placeholder={'หน่วยขันน้ำ'}></input>
                    </div>
                    <div className='form-input'>
                        <span>ส่วนลด</span>
                        <input className='form-control' id={'_discount'} type='number' value={_discount} onChange={(e) => setDiscount(e.target.value)} placeholder={'ส่วนลดราคา'}></input>
                    </div>
                    <div className='form-input'>
                        <span>ลายระเอียด</span>
                        <input className='form-control' id={'_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)} placeholder={'ลายละเอียด'}></input>
                    </div>
                    <div className='form-input'>
                        <span>ไฟล์</span>
                        <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                    </div>
                    <div className='form-input-btn'>

                        <div className='form-btn'>
                            <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                        </div>                   
                    </div>
                </div>
            </div>

        </form>
    )

}


export default CreateFormProduct;