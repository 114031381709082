import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import SerialCode from '../../../Pages/SerialCode';
import { ModelCreateCode } from '../../classcomponent/modelCodeSecretFeature';
import ItemPrefix, { getPrefixData } from '../../common/prefix';


function CreateCode () {

    const [_amount , setAmount] = useState(1);
    const [_number , setNumber] = useState(1);
    const [_lineNumber , setLineNumber] = useState(0);
    const [_typeTime , setType] = useState('hours');
    const [_member , setMember] = useState('');
    const [_prefix , setPrefix] = useState('xms');
    const [_description , setDescription] = useState('');
    const [_prefixArray , setPrefixArray] = useState([]);


    const RandomGenCode = (max , min) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const MemberPrefix = () => getPrefixData().then((doc) => {
        setPrefixArray(doc)
    })

    useEffect(() => {

        MemberPrefix()
        

        return () => {
            MemberPrefix()
        }
    } , [])

    const AddNewCode = (_code) => {

        const jsonPrefix = JSON.parse(_prefix);

        const model = new ModelCreateCode();
        model.prefix = jsonPrefix;
        model.member = _member;
        model.id = `${model.prefix.code}${_lineNumber}${_code}`;
        model.code = `${model.prefix.code}${_lineNumber}${_code}`;
        model.timestamp = _.timeStamp;
        model.timeData.option.timeNumber = _number;
        model.timeData.option.timeType = _typeTime;
        model.description = _description;

        _.jarvis_code.doc(model.id).set({
            prefix:model.prefix,
            code:model.code,
            member:model.member,
            status:model.status,
            timeData:model.timeData,
            timestamp:model.timestamp,
            description:model.description,
        });

        _.cg_logHistory.get().then((snap) => {
            const data = snap.data()
            let total = data.totalCodeGenerated
            let updateTotal = total += 1
            _.jarvis_logHistory.update({
                'totalCodeGenerated':updateTotal
            })

            _.jarvis_logHistory.collection('members_code').add({
                prefix:model.prefix,
                code:model.code,
                member:model.member,
                status:model.status,
                timeData:model.timeData,
                timestamp:model.timestamp,
                description:model.description,
            })
        }).catch((err) => {
            console.log(err);
        }) 


    }

    const formData = async (e) => {

        e.preventDefault();
    

        for(var i=0; i< _amount; i++) {

            var gen = RandomGenCode(0 , 99999);
            let compare = null;
            if(gen >= 0 && gen <= 9) {
                compare = `0000${gen}`;
            }else if(gen >= 10 && gen <= 99 ) {
                compare = `000${gen}`;
            }else if(gen >= 100 && gen <= 999) {
                compare = `00${gen}`;
            }else if(gen >= 1000 && gen <= 9999) {
                compare = `0${gen}`;
            }else if(gen >= 10000 && gen <= 99999) {
                compare = gen;
            }
            AddNewCode(compare);
        }
        CloseFormCreateData();
    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataJarvis');
        _form.style.top = '-100%';
    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataJarvis');
        _form.style.top = '-100%';
        refreshPage();
    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<SerialCode/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateData' id='formCreateDataJarvis'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >สร้างรหัสโค้ด Jarvis</h1>
                    </div>
                    <div className='form-input'>
                        <span>จำนวนที่ต้องการคีย์</span>
                        <input className='form-control' id={'_amount'} type='number' value={_amount} onChange={(e) => setAmount(e.target.value)} placeholder='จำนวน' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>คำนำหน้า</span>
                        <select className='form-control' defaultValue={'DEFAULT'} value={_prefix} onChange={(e) => setPrefix(e.target.value)} required>
                            {_prefixArray.map((doc) => {
                                return <ItemPrefix doc={doc}/>
                            })}
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>รหัสเลขให้บริการ</span>
                        <input className='form-control' id={'_number'} type='number' value={_lineNumber} onChange={(e) => setLineNumber(e.target.value)} placeholder='รหัสไลน์ให้บริการ' max={1} maxLength={1} required></input>
                    </div>
                    <div className='form-input'>
                        <span>ผู้ใช้งาน</span>
                        <input className='form-control' id={'_member'} type='text' value={_member} onChange={(e) => setMember(e.target.value)} placeholder='ผู้ใช้งาน'></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>ประเภทของเวลา</span>
                        <select className='form-control' defaultValue={'DEFAULT'} value={_typeTime} onChange={(e) => setType(e.target.value)} required>
                            <option value='DEFAULT' disabled>เลือกประเภท</option>
                            <option value='minute'>นาที</option>
                            <option value='hours'>ชั่วโมง</option>
                            <option value='day'>วัน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>จำนวนระยะเวลา</span>
                        <input className='form-control' id={'_number'} type='number' value={_number} onChange={(e) => setNumber(e.target.value)} placeholder='ระยะเวลา' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>หมายเหตุ</span>
                        <input className='form-control' id={'_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)}  placeholder='หมายเหตุ'></input>
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default CreateCode;