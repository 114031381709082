import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import MainPage from '../../../Pages/MainPage';
import ServiceAccount from '../../../Pages/ServiceAccount';
import ReactDOM from 'react-dom';


function CreateServiceAccount () {

    const [_userValue , setUserValue] = useState('Jarvis-24');
    const [_userStatus , setUserStatus] = useState('');
    const [_userMember , setUserMember] = useState('');


    const RandomGenCode = (max , min) => {

        return Math.floor(Math.random() * (max - min + 1) + min)

    }


    const formData = async (e) => {

        e.preventDefault();
    

        const snapshot = await _.db.doc().collection('member_data').get();
        let duplipcate = [];

        snapshot.forEach((doc) => {

            const codeID = doc.id;
            duplipcate.push({codeID});

        });


        var _userID = 'AccountAssJarvis-24-' + RandomGenCode(100000 , 999999);
        var _code = RandomGenCode(100000 , 999999);
        const found = duplipcate.find(c => c === _userID);
        found != null ? _userID = RandomGenCode(100000 , 999999) : AddNewCode(_userID , _code);


    }

    const AddNewCode = async (_userID , _code) => {


        const today = new Date();
        const _time = today.toUTCString();
        var _modelStatus = null;

        _userStatus === 'true' ? _modelStatus = true : _modelStatus = false;
        const _properties = {status:_modelStatus , description:_userMember , value:_userValue};
        
        await _.db.doc(_userID).set({

            userID:_userID,
            password:_userID,
            properties:_properties,
            device:{count:0 , signedIn:null},
            create:_time

        })
        

        await _.db.doc(_userID).collection('member_data').doc(`${_code}`).set({

            code:_code,
            status:true,
            expire:null,
            member:null,
            properties:{number:1 , typeTime:'day' , timeStamp:false},
            device:{count:0 , token:_userID , onTime:null},
            event:{status:true , reward:null , typereward:null , time:null , description:null , token:null},
            create:_time

        });

        await _.db.doc(`OrderCode`).collection('ListCodeData').doc(`${_code}`).set({

            code:_code,
            member:null,
            tokenID:_userID
    
        });

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataServiceAccount');
        _form.style.top = '-100%';

        var _input_number = document.getElementById('_number'),
            _input_member = document.getElementById('_member'),
            _input_amount = document.getElementById('_amount');


        _input_amount.value = null;
        _input_number.value = null;
        _input_member.value = null;

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataServiceAccount');
        _form.style.top = '-100%';

        var _input_number = document.getElementById('_number'),
            _input_member = document.getElementById('_member'),
            _input_amount = document.getElementById('_amount');


        _input_amount.value = null;
        _input_number.value = null;
        _input_member.value = null;

        refreshPage();

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<ServiceAccount/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataServiceAccount' id='formCreateDataServiceAccount'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >สร้างรหัสผู้เช่า</h1>
                    </div>
                    <div className='form-input'>
                        <span>รหัส ID</span>
                        <input className='form-control' id='_id-s' type='text'  placeholder='สุ่มอัตโนมัติ' readOnly></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>รหัส Password</span>
                        <input className='form-control' id='_password-s' type='text'  placeholder='สุ่มอัตโนมัติ' readOnly></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>ช่องทางให้บริการ</span>
                        <input className='form-control' id={'_valueService-s'} type='text' value={_userValue} onChange={(e) => setUserValue(e.target.value)} placeholder='ช่องทางให้บริการ' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>สถานะให้บริการ</span>
                        <select className='form-control' defaultValue={'DEFAULT'} onChange={(e) => setUserStatus(e.target.value)} required>
                            <option value='DEFAULT' disabled>เลือกประเภท</option>
                            <option value={true}>เปิดให้บริการ</option>
                            <option value={false}>ปิดให้บริการ</option>
                        </select>
                    
                    </div>
                    <div className='form-input'>
                        <span>ผู้ใช้งาน</span>
                        <input className='form-control' id={'_member-s'} type='text' onChange={(e) => setUserMember(e.target.value)} placeholder='ผู้ใช้งาน'></input>
                    
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>
    )

}


export default CreateServiceAccount;