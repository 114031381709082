import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelServiceAccount from '../../classcomponent/modelServiceAccount';

function DelServiceAccount () {
    
    const delData = async () => {


        const _modelID =  ModelServiceAccount.prototype.id;

        const query = document.querySelector(`[id=data${_modelID}]`);
        query.remove();
        await _.db.doc(_modelID).delete();

        CloseFormDelDataUser();
    }

    const CloseFormDelDataUser = () => {

        var _form = document.getElementById('formDelDataServiceAccount');
        _form.style.top = '-100%';
        
    }

    const CloseFormDelData = (e) => {

        e.preventDefault();
        var _form = document.getElementById('formDelDataServiceAccount');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelDataServiceAccount' id='formDelDataServiceAccount'>
            <div className='box-data-search'>

                <div className='text-white'>
                    <h1 >ลบรหัสผู้เช่า</h1>
                </div>

                <div className='form-input'>
                    <span>รหัส ID ผู้เช่า </span>
                    <input className='form-control' id={'_delUserID-s'} type='text' readOnly></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 
            </div>
        </div>
    )

}


export default DelServiceAccount;