import React, { useEffect, useState } from 'react'
import _ from '../utils/firebase';
import ReactDOM from 'react-dom';
import MainPage from './MainPage';
import StorePage from './StoreProduct';
import ItemCardsJarvis from '../components/items/cards/itemCrads';

function CardsPage () {

    const [item , setItem] = useState([]);
    async function fetchData () {

        const snapshot = await _.cardsDB.orderBy('option.type' , 'asc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            const id = doc.id;
            const data = doc.data();

            listItem.push({id,...data});
        });
        
        setItem(listItem);

    }

    const CreateNewCards = () => {

        var _form = document.getElementById('formCreateDataCards');
        _form.style.top = '0%';
    }

    const BackToStore = () => {
        ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
        ReactDOM.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'));
    }

    useEffect(() => {

        fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <div>
                    <button className='btn btncreate btn-games' onClick={BackToStore}>ย้อนกลับ</button>
                    <span className='text-header'><i className="fas fa-align-justify"></i> การ์ดสะสม สุ่มรางวัล </span>
                </div>
                <div>
                    <button className='btn btncreate btn-games' onClick={CreateNewCards}>สร้างการ์ด / สุ่มรางวัล</button>
                </div>
            </div>
            <div className="list-container-card product">
                {item.map((d) => (
                  <ItemCardsJarvis key={d.id} item={d}/>
                ))}
            </div>
        </div>

    )

}


export default CardsPage;