import React, { useEffect, useState } from 'react'
import ItemGames from '../components/items/games/itemGames';
import _ from '../utils/firebase';
import ReactDOM from 'react-dom';
import MainPage from './MainPage';
import CompanyGame from './CompanyGame';


function AllGames () {

    const [item , setItem] = useState([]);

    const fetchDataGames = async () => {

        const _localCompany = localStorage.getItem('company');
        const snapshot = await _.games.orderBy('company_name' , 'asc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            if(doc.data().company_name === _localCompany) {

                const id = doc.id;
                const data = doc.data();

                listItem.push({id,...data});
            }
            
        });

        setItem(listItem);

    }

    useEffect(() => {

        fetchDataGames();

    },[])

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataGames');
        _form.style.top = '0';

    }

    const BackToCompanyGame = () => {

        ReactDOM.render(<MainPage pages={<CompanyGame/>}/> , document.getElementById('root'));

    }
    

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <div className='Group-Allgame'>
                    <button className='btn btncreate' onClick={BackToCompanyGame}>ย้อนกลับ</button>
                    <span className='text-header'><i className="fas fa-align-justify"></i> รวมเกม </span>
                </div>
                <button className='btn btncreate' onClick={OpenFormCreateData}>เพิ่มเกม</button>
            </div>

            <div>
                <div className="list-container-card">
                    {item.map((d) => (
                        <ItemGames key={d.id} data={d}/>
                    ))}
                </div>
            </div>

            
        </div>

    )

}


export default AllGames;