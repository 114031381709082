class ModelCode {

    code = null;
    status = null;
    expire = null;
    number = null;
    typeTime = null;
    member = null;

}

export class ModelCodeJarvis {

    id = null;
    prefix = null;
    member = null;
    code = null;
    status = false;
    expire = null;
    typeTime = null;
    timestamp = null;
    description = null;

}

export default ModelCode;