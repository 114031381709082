import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCodeFriday from '../../classcomponent/modelCodeFriday';

function DelCodeFriday () {
    
    const delData = async () => {


        const _id =  `member_data` ;
        const _modelCode = ModelCodeFriday.prototype.code;

        const query = document.querySelector(`[id=data${_modelCode}]`);
        query.remove();
        await _.friday.doc(_id).collection('friday_code').doc(`${_modelCode}`).delete();

        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataFriday');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelDataFriday' id='formDelDataFriday'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >ลบรหัสโค้ด</h1>
                </div>

                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_delcode-f'} type='number' readOnly></input>
                </div>

                <div className='form-input'>
                    <span>เวลาหมดอายุ</span>
                    <input className='form-control' id={'_delexpire-f'} type='text'  readOnly></input>
                </div>

                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_delmember-f'} type='text' readOnly></input>
                </div>

                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelCodeFriday;