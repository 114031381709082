/* eslint-disable jsx-a11y/scope */

import '../../../public/stylesheets/style-card.css';
import ReactDom from 'react-dom';
import MainPage from "../../../Pages/MainPage";
import _Cards from '../../classcomponent/modelsCards';
import CardsPage from '../../../Pages/CardsPage';

const ItemCardsJarvis = (props) => {

    
    const updateData = async () => {

        const _modelCards = new _Cards.ModelCards();

        _modelCards.id = props.item.id;
        _modelCards.name = props.item.name;
        _modelCards.created = props.item.created;
        _modelCards.status = props.item.status;
        _modelCards.option.percent = props.item.option.percent;
        _modelCards.option.rate = props.item.option.rate;
        _modelCards.option.description = props.item.option.description;
        _modelCards.option.type = props.item.option.type;

        _Cards.ListCards.prototype.id = _modelCards.id;
        _Cards.ListCards.prototype.name = _modelCards.name;
        _Cards.ListCards.prototype.status = _modelCards.status;
        _Cards.ListCards.prototype.description = _modelCards.option.description;
        _Cards.ListCards.prototype.rate = _modelCards.option.rate;
        _Cards.ListCards.prototype.percent = _modelCards.option.percent;
        _Cards.ListCards.prototype.type = _modelCards.option.type;
        _Cards.ListCards.prototype.isUpdate = true;

        var _form = document.getElementById('formEditDataCards');
        _form.style.top = '0';
       
        return(

            <div>
                {ReactDom.render(<MainPage pages={''}/> , document.getElementById('root'))}
                {ReactDom.render(<MainPage pages={<CardsPage/>}/> , document.getElementById('root'))}
            </div>

        )

    }

    const delData = async () => {

        _Cards.ListCards.prototype.id = props.item.id;
        _Cards.ListCards.prototype.name = props.item.name;

        var _form = document.getElementById('formDelDataCards');
        _form.style.top = '0';


        return(

            <div>
                {ReactDom.render(<MainPage pages={''}/> , document.getElementById('root'))}
                {ReactDom.render(<MainPage pages={<CardsPage/>}/> , document.getElementById('root'))}
            </div>

        )

    }
    return (

        <div className="listCard-product" id={`data${props.item.id}`}>

            <div className="card">
                <div className="img-offset img-product img-card">
                    <img src={props.item.images.source} alt={props.item.images.id}></img>
                    <div className="shadow-img"></div>
                </div>
                <div className="container-card container-card-product">
                    <span className="sp-card pd-name">{`การ์ด : ${props.item.name}`}</span>
                    <span className="sp-card pd-name">{`ลายละเอียด : ${props.item.option.description ? props.item.option.description : 'ไม่มี'}`}</span>
                    <div className='pd pd-type'>
                        <div>
                            <span className={ props.item.status ? "sp-card text-status-on pd" : "sp-card text-status-off pd"}>{props.item.status ? 'สถานะ : เปิดใช้งาน' : 'สถานะ : ปิดใช้งาน'}</span>
                        </div>
                        <div>
                            <span className={"sp-card pd"}>{`ประเภท : ${props.item.option.type === 'card' ? 'การ์ดสะสม' : props.item.option.type === 'time' ? 'เพิ่มเวลา' : 'ขันน้ำ'}`}</span>
                        </div>
                    </div>
                    <div className="description-product">
                        <div className="pd pd-type">
                            <span className="sp-card">{`เปอร์เซ็นต์ : `}
                                <span className='sp-card-text'>{`${props.item.option.percent}%`}</span>
                            </span>
                            <span className="sp-card">{`ระดับ : `}
                                <span className={`${props.item.option.rate === 'SSR' ? 'sp-card-text text-red' : props.item.option.rate === 'SR' ? 'sp-card-text text-org' : 'sp-card-text'}`}>{`${props.item.option.rate === 'SSR' ? 'หายาก' : props.item.option.rate === 'SR' ? 'ยาก' : props.item.option.rate === 'R' ? 'ทั่วไป' : 'ไม่มี'}`}</span>
                            </span>
                        </div>
                    </div>
                    <span className="sp-card">
                        <button className="btn text-white btnedit" id={`edit${props.item.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                        <button className="btn text-white btndel" id={`del${props.item.id}`} onClick={delData}>ลบข้อมูล</button>
                    </span>
                    
                </div>

            </div>
        </div>

    )
}

export default ItemCardsJarvis;