import { useEffect, useState } from "react"
import MainPage from "../../../Pages/MainPage";
import _ from "../../../utils/firebase";
import ReactDOM from 'react-dom';
import '../../../public/stylesheets/style-confirm.css';
import StorePage from "../../../Pages/StoreProduct";



const ExchangeData = () => {

    const [rateSSR , setRateSSR] = useState(1);
    const [rateSR , setRateSR] = useState(1);
    const [rateR , setRateR] = useState(1);
    const [rateBowl , setRateBowl] = useState(1);

    const [_getstatus , setStatus] = useState(false);

    const GetDataExchange = async () => {

        setStatus(true);

        const snapshot = await  _.events_songkran.get();
        const optionEvent = snapshot.data().exchange.option.rateChange;

        setRateSSR(optionEvent.ssr_card);
        setRateSR(optionEvent.sr_card);
        setRateR(optionEvent.r_card);
        setRateBowl(optionEvent.bowl_token);

    }

    useEffect(() => {

        if(!_getstatus) GetDataExchange();

    })

    const EditNumberExchange = async (e) => {

        e.preventDefault();

        await _.events_songkran.update({
            'exchange.option.rateChange.ssr_card':parseInt(rateSSR),
            'exchange.option.rateChange.sr_card':parseInt(rateSR),
            'exchange.option.rateChange.r_card':parseInt(rateR),
            'exchange.option.rateChange.bowl_token':parseInt(rateBowl),
        });

        var _form = document.getElementById('formEditExchanheData');
        _form.style.top = '-100%';


        setTimeout(() => {
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'));
        },500)

    }


    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formEditExchanheData');
        _form.style.top = '-100%';
    }


    return(

        <form>

            <div className='formEditExchanheData' id='formEditExchanheData'>

                <div className='box-data'>
                    <div className='form-input'>
                        <span>จำนวนใช้การ์ด SSR</span>
                        <input className='form-control' id={'_ssr'} type='number' value={rateSSR} onChange={(e) => setRateSSR(e.target.value)} required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>จำนวนใช้การ์ด SR</span>
                        <input className='form-control' id={'_sr'} type='number' value={rateSR} onChange={(e) => setRateSR(e.target.value)} required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>จำนวนใช้การ์ด R</span>
                        <input className='form-control' id={'_r'} type='number' value={rateR} onChange={(e) => setRateR(e.target.value)} required></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>จำนวนได้รับขัน</span>
                        <input className='form-control' id={'_bowl'} type='number' value={rateBowl} onChange={(e) => setRateBowl(e.target.value)} required></input>
                    
                    </div>
                    <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' onClick={EditNumberExchange} type='submit' >ยืนยันปรับแก้ไข</button>
                            <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                        </div>                   
                    </div>
                </div>
            </div>
        </form>
    )

} 

export default ExchangeData;