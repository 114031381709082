import CompanyGame from '../../Pages/CompanyGame';
import SerialCode from '../../Pages/SerialCode';
import SerialCodeFriday from '../../Pages/SerialCodeFriday';
import UsersHistory from '../../Pages/UserHistory';
import '../../public/stylesheets/style-sidebar.css';
import ReactDom from 'react-dom';
import { useState } from 'react';
import ServiceAccount from '../../Pages/ServiceAccount';
import LoginPage from '../../Pages/Login';
import _ from '../../utils/firebase';
import MainPage from '../../Pages/MainPage';
import StorePage from '../../Pages/StoreProduct';
import SerialCodeFeature from '../../Pages/SerialCodeFeatureCode';
import logo_friday from '../../images/logo_friday.png'
import logo_jarvis from '../../images/logo_jarvis.png'
import logo_CG from '../../images/logo_web_CG.png'

const MainSideBar = (props) => {

    // const RenderJarvisCode = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<SerialCode/>}/> , document.getElementById('root'));
    // }

    // const RenderFridayCode = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<SerialCodeFriday/>}/> , document.getElementById('root'));
    // }

    /// New Feature สำหรับ ฟีเจอร์ลับ
    const RenderServiceFeatureCode = (e) => {
        e.preventDefault();
        ReactDom.render(<MainPage pages={<SerialCodeFeature/>}/> , document.getElementById('root'));
    }

    // const RenderHistory = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<UsersHistory/>}/> , document.getElementById('root'));
    // }

    // const RenderGamesCompany = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<CompanyGame/>}/> , document.getElementById('root'));
    // }



    // const RenderServiceJarvisCode = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<ServiceAccount/>}/> , document.getElementById('root'));
    // }

    // const RenderStore = (e) => {
    //     e.preventDefault();
    //     ReactDom.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'));
    // }

    const RenderSignOut = (e) => {
        e.preventDefault();

        _.auth.signOut();

        setTimeout(() => {

            window.location.reload();
            
        },500);
    }

    return(

        <div className='wrapper'>
            <div className="sidebar">
                <h2>ระบบแอดมิน</h2>
                <ul>
                    {/* <li><a href={'/'} onClick={RenderJarvisCode}><i className="fas fa-key"></i>คีย์รหัสโค้ด Jarvis 2024</a></li> */}
                    {/* <li><a href={'/'} onClick={RenderFridayCode}><i className="fas fa-key"></i>คีย์รหัสโค้ด Friday 2023</a></li> */}
                    <li><a href={'/'} onClick={RenderServiceFeatureCode}><i className="fas fa-key"></i>คีย์รหัสโค้ด CG(Code Game Soft)</a></li>
                    {/* <li><a href={'/'} onClick={RenderHistory}><i className="fas fa-history"></i>ประวัติรางวัล</a></li> */}
                    {/* <li><a href={'/'} onClick={RenderStore}><i className="fas fa-store"></i>ร้านค้า / การ์ดสะสม สุ่มรางวัล</a></li> */}
                    {/* <li><a href={'/'} onClick={RenderGamesCompany}><i className="fas fa-building"></i>ค่ายเกม</a></li> */}
                    {/* <li><a href={'/'} onClick={RenderServiceJarvisCode}><i className="fas fa-users"></i>บริการเช่า</a></li> */}
                    <li><a href={'/'} onClick={RenderSignOut}><i className="fas fa-sign-out-alt"></i>ออกจากระบบ</a></li>
                </ul>
                <div className="social_app">
                    {/* <a href="http://jarvis-24.com/"><img src={logo_jarvis} alt='jarvis 2024'/></a> */}
                    {/* <a href="https://friday2022-3a53c.web.app/"><img src={logo_friday} alt='friday 2022'/></a> */}
                    <a href="https://friday2022-3a53c.web.app/"><img src={logo_CG} alt='Code Game Soft'/></a>
                </div>
            </div>
            <div className="main_content">
                <div className="col-dataSerial">
                    <div className="container-serial">
                        <div className="row-serial">
                            <div className="col-serial">
                                <div className="list-card"> 
                                    <div className="card-body">
                                        {props.pages}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>           
                </div>
            </div>

        </div>

    )

}

export default MainSideBar;