import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelGames from '../../classcomponent/modelGames';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import AllGames from '../../../Pages/Games';

function EditGames () {
    

    const [_company , setCompany] = useState();
    const [_name , setName] = useState();
    const [_status , setStatus] = useState();
    const [_img , setImg] = useState();

    const [_itemCompany , setItemCompany] = useState([]);

    const fachdata = async () => {

        const snapshot = await _.company.get();
        let listItem = [];

        snapshot.forEach((doc) => {

            const data = doc.data();

            listItem.push({data});

        });

        setItemCompany(listItem);

    }

    useEffect(() => {

        fachdata();

    })

    
    const UpdateCompany = async (e) => {

        e.preventDefault();


        const _modelID = ModelGames.prototype.id;
        var _modelName = null;
        var _modelCompany = null;
        var _modelStatus = null;

        _name === undefined ? _modelName = ModelGames.prototype.name : _modelName = _name;
        _company === undefined ? _modelCompany = ModelGames.prototype.company : _modelCompany = _company;
        _status === undefined ? _modelStatus = ModelGames.prototype.status : _modelStatus = _status;

        if(_status === undefined) {
            _modelStatus = ModelGames.prototype.status;
        } else {
            _modelStatus = _status;
            _modelStatus === 'true' ? _modelStatus = true : _modelStatus = false;

        }

        if(_img === undefined) {


            await _.games.doc(_modelID).update({

                game_name:_modelName,
                company_name:_modelCompany,
                status:_modelStatus,

            });

        } else {

            await uploadFileEdit(_name);

            await _.games.doc(_modelID).update({

                game_name:_modelName,
                company_name:_modelCompany,
                status:_modelStatus,

            });

        }
      
        CloseFormEditDataToRelode();
    }

    const uploadFileEdit = async (_name , _id) => {

        _.storage.ref(`page/static/images/company/${_name}`).put(_img).then(()=>{
            console.log('อัพโหลดเรียบร้อย');
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }


    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataGames');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataGames');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<AllGames/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataGames' id='formEditDataGames'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 id='title-game'>แก้ไขข้อมูลเกม</h1>
                </div>

                <div className='form-input'>
                    <span>ค่ายเกม</span>
                    <select className='form-control' id={'_edcompany-g'} defaultValue={'default'} onChange={(e) => setCompany(e.target.value)}>
                        <option value={'default'}>เลือกค่ายเกม</option>
                        {_itemCompany.map((d) => (
                            <option key={d.data.name} value={d.name}>{d.data.name}</option>
                        ))}

                    </select>
                </div>

                <div className='form-input'>
                    <span>ชื่อเกม</span>
                    <input className='form-control' id={'_edname-g'} type='text' onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control'  id={'_edstatus-g'} value={_status}  onChange={(e) => setStatus(e.target.value)} >
                            <option value={'default'}>เลือกสถานะ</option>
                            <option value={true}>เปิดใช้งาน</option>
                            <option value={false}>ปิดใช้งาน</option>
                    </select>
                </div>
                <div className='form-input'>
                    <span>อัพโหลดรูปภาพ</span>
                    <input className='form-control input-file' id={'_edfiles-g'} type='file' onChange={(e) => setImg(e.target.files[0])}></input>
                   
                </div>
               
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateCompany}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditGames;