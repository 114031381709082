import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ModelCompany from '../../classcomponent/modelCompany';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import CompanyGame from '../../../Pages/CompanyGame';


function EditCodeCompany () {
    
    const [_status , setStatus] = useState();
    const [_width , setWidth] = useState();
    const [_height , setHeight] = useState();
    const [_img , setImg] = useState();


    const UpdateCompany = async (e) => {

        e.preventDefault();


        const _modelID = ModelCompany.prototype.id;
        const _name = ModelCompany.prototype.name;
        const _path = ModelCompany.prototype.path;
        var _modelWidth = null;
        var _modelHeight = null;
       

        let _setStatus = false;

        if(_status === undefined) {

            _setStatus = ModelCompany.prototype.status;

        } else {

            _setStatus = _status;
            _setStatus === 'true' ? _setStatus = true : _setStatus = false;

        }

        _width === undefined ? _modelWidth = ModelCompany.prototype.width : _modelWidth = parseInt(_width);
        _height === undefined ? _modelHeight = ModelCompany.prototype.height : _modelHeight = parseInt(_height);

        
        const _size = {height:_modelHeight , width:_modelWidth};
        const _properties = {path:_path , size:_size};

        if(_img === undefined) {


            await _.company.doc(_modelID).update({

                status:_setStatus,
                properties:_properties

            });

        } else {

            await uploadFileEdit(_name);

            await _.company.doc(_modelID).update({

                status:_setStatus,
                properties:_properties

            });

        }
      
        CloseFormEditDataToRelode();
    }

    const uploadFileEdit = async (_name , _id) => {

        _.storage.ref(`page/static/images/company/${_name}`).put(_img).then(()=>{
            console.log('อัพโหลดเรียบร้อย');
            //CloseFormEditDataToRelode();
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }


    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataCompany');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataCompany');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<CompanyGame/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataCompany' id='formEditDataCompany'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูล</h1>
                </div>

                <div className='form-input'>
                    <span>ค่ายเกม</span>
                    <input className='form-control' id={'_edname-c'} type='text' readOnly></input>
                </div>
                 <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control'  id={'_edstatus-c'} value={_status}  onChange={(e) => setStatus(e.target.value)} >
                        <option value={'true'} >เปิดใช้งาน</option>
                        <option value={'false'}>ปิดใช้งาน</option>
                    </select>

                   
                </div>
                <div className='form-input'>
                    <span>อัพโหลดรูปภาพ</span>
                    <input className='form-control input-file' id={'_edfiles-c'} type='file' onChange={(e) => setImg(e.target.files[0])}></input>
                   
                </div>
                <div className='form-input'>
                    <span>ขนาดความกว้าง</span>
                    <input className='form-control' id={'_edwidth-c'} type='number'  onChange={(e) => setWidth(e.target.value)} ></input>
                   
                </div>
                <div className='form-input'>
                    <span>ขนาดความสูง</span>
                    <input className='form-control' id={'_edheight-c'} type='number'  onChange={(e) => setHeight(e.target.value)}></input>
                   
                </div>
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateCompany}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCodeCompany;