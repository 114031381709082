import React, { useEffect, useState } from 'react'
import ItemCard from '../components/items/company/listCard';
import _ from '../utils/firebase';

function CompanyGame () {

    const [item , setItem] = useState([]);
    async function fetchData () {

        const snapshot = await _.company.get();
        let listItem = [];

        snapshot.forEach((doc) => {

            const id = doc.id;
            const data = doc.data();

            listItem.push({id,...data});
        });

        setItem(listItem);

    }

    const OpenFormCreateDataCompany = () => {

        var _form = document.getElementById('formCreateDataCompany');
        _form.style.top = '0';
    }

    useEffect(() => {

        fetchData();

    },[])

    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รวมค่ายเกม </span>
                <div className='group-btn'>
                    <button className='btn btncreate btn-games' onClick={OpenFormCreateDataCompany}>เพิ่มค่ายเกม</button>
                </div>
                
            </div>

                

            <div className="list-container-card">
                    {item.map((d) => (
                        <ItemCard key={d.id} data={d}/>
                    ))}
            </div>
        </div>

    )

}


export default CompanyGame;