import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCa2gWhAZxnkHT2hNGt2W-JO52_vqSwz_w",
    authDomain: "jarvis-24webservice.firebaseapp.com",
    databaseURL: "https://jarvis-24webservice-default-rtdb.firebaseio.com",
    projectId: "jarvis-24webservice",
    storageBucket: "jarvis-24webservice.appspot.com",
    messagingSenderId: "149920931937",
    appId: "1:149920931937:web:58238a51ccf16fbaef4a20",
    measurementId: "G-DVN4Z4TV3P"
};

const app = firebase.initializeApp(firebaseConfig);
const prefix = app.firestore().collection('t_prefix')
const db = app.firestore().collection('service_data');
const jarvis_code = app.firestore().collection('t_code_jarvis');
const friday_code = app.firestore().collection('jarvis_friday');
const secret_code = app.firestore().collection('t_code_secret_feature');
const usersHistory = app.firestore().collection('user_data');
const company = app.firestore().collection('company-data');
const games = app.firestore().collection('game-data');
const storage = app.storage();
const products = app.firestore().collection('events_data').doc('Songkran').collection('product_data');
const gs_store = 'https://storage.googleapis.com/jarvis-24webservice.appspot.com/page/static/images/company/';
const auth = app.auth();
const timeStamp = firebase.firestore.FieldValue.serverTimestamp();
const cardsDB = app.firestore().collection('events_data').doc('Songkran').collection('cards_collection');
const imagesDB = app.firestore().collection('events_data').doc('Songkran').collection('images_data');
const events_songkran = app.firestore().collection('events_data').doc('Songkran');
const jarvis_logHistory = app.firestore().collection('log_history').doc('generated_code_jarvis')
const cg_logHistory = app.firestore().collection('log_history').doc('generated_code_cg_game')


const _ = {
    db,
    prefix,
    jarvis_code,
    friday_code,
    secret_code,
    usersHistory,
    company,
    games,
    gs_store,
    storage,
    auth,
    products,
    timeStamp,
    cardsDB,
    imagesDB,
    events_songkran,
    jarvis_logHistory,
    cg_logHistory,
}

export default _;