/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-serial.css';
import ModelCodeFriday from "../../classcomponent/modelCodeFriday";

const ItemObjFriday = (props) => {

    const updateData = async () => {

        ModelCodeFriday.prototype.code = props.data.id;
        ModelCodeFriday.prototype.member = props.member;

        var _form = document.getElementById('formEditDataFriday');
        _form.style.top = '0';
       
        var _code = document.getElementById('_edcode-f'),
            _status = document.getElementById('_edstatus-f'),
            _expire = document.getElementById('_edexpire-f'),
            _member = document.getElementById('_edmember-f');



        _code.value = props.data.id;
        props.status ? _status.value = 'ใช้งานได้' : _status.value = 'ไม่สามารถใช้งานได้';
        props.expire === null ? _expire.value  = '-' :_expire.value = props.expire;
        props.member === '' ? _member.value = '-' : _member.value = props.member;

    }

    const delData = async () => {

        ModelCodeFriday.prototype.code = props.data.id;
        ModelCodeFriday.prototype.member = props.member;

        var _form = document.getElementById('formDelDataFriday');
        _form.style.top = '0';

        var _code = document.getElementById('_delcode-f'),
            _expire = document.getElementById('_delexpire-f'),
            _member = document.getElementById('_delmember-f');



        _code.value = props.data.id;
        props.expire === null ? _expire.value  = '-' :_expire.value = props.expire;
        props.member === '' ? _member.value = '-' : _member.value = props.member;

    }


    return (

        <tr id={`data${props.data.id}`} key={props.id}>
            <td scope="col">{props.crete}</td>
            <td scope="col">{props.data.id}</td>
            <td className={ props.status ? "text-status-on" : "text-status-off"} scope="col">{props.status ? 'ใช้งานได้' : 'หมดอายุ'}</td>
            <td scope="col">{props.expire === null ? '-' : props.expire}</td>
            <td scope="col">{props.member === null || props.member === '' ? '-' : props.member}</td>
            <td>
                <div>
                    <button className="btn text-white btnedit" id={`edit${props.code}`} onClick={updateData}>แก้ไขข้อมูล</button>
                    <button className="btn text-white btndel" id={`del${props.code}`} onClick={delData}>ลบข้อมูล</button>
                </div>
            </td>
        </tr>
    )
}

export default ItemObjFriday;