import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import StorePage from '../../../Pages/StoreProduct';
import _Product from '../../classcomponent/modelProducts';


function EditProduct () {

    const [_img , setImg] = useState();
    const [_name , setName] = useState('');
    const [_status , setStatus] = useState(true);
    const [_type , setType] = useState('ticket');
    const [_price , setPrice] = useState(0);
    const [_discount , setDiscount] = useState(0);
    const [_description , setDescription] = useState('');
    const [_sold , setSold] = useState(0);

    useEffect(() => {

        if(_Product.ListProducts.prototype.isUpdate) SetState();
        
    })

    const SetState =()=> {
        
        setName(_Product.ListProducts.prototype.name);
        setStatus(_Product.ListProducts.prototype.status);
        setType(_Product.ListProducts.prototype.type);
        setPrice(_Product.ListProducts.prototype.original);
        setDiscount(_Product.ListProducts.prototype.discount);
        setDescription(_Product.ListProducts.prototype.description);
        setSold(_Product.ListProducts.prototype.sold);
        _Product.ListProducts.prototype.isUpdate = false;

    }



    const UpdateCompany = async (e) => {

        e.preventDefault();


        const _modelID = _Product.ListProducts.prototype.id;
        let _setStatus = null;
        
        switch (_status) {
            case true:
                _setStatus = true;
                break;
            case false:
                _setStatus = false;
                break;
            case 'true':
                _setStatus = true;
                break;
            case 'false':
            _setStatus = false;
            break;
            default:
                break;
        }

        if(_img === undefined) {

            await StoreUpdate(_modelID , _setStatus);
            

        } else {

            await uploadFileEdit(_name);

            await StoreUpdate(_modelID , _setStatus);

        }
      
        CloseFormEditDataToRelode();
    }

    const StoreUpdate = async (_modelID , _setStatus) => {

        await _.products.doc(_modelID).update({

            name:_name,
            status:_setStatus,
            option:{

                description:_description,
                price:{

                    discount:parseInt(_discount),
                    original:parseInt(_price)
                },
                sold:_sold,
                type:_type
            }
        });

    }

    const uploadFileEdit = async (_name , _id) => {

        _.storage.ref(`page/static/images/events/Songkran/${_type}/${_name}`).put(_img).then(()=>{
            console.log('อัพโหลดเรียบร้อย');
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }


    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataProduct');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataProduct');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<StorePage/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataProduct' id='formEditDataProduct'>
            <div className='box-data'>
                <div className='text-white'>
                    <h1 >แก้ไขข้อมูลสินค้า</h1>
                </div>
                <div className='form-input'>
                    <span>ชื่อสินค้า</span>
                    <input className='form-control' id={'_edname-p'} type='text' value={_name} onChange={(e) => setName(e.target.value)} ></input>
                </div>
                <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control' defaultValue={true} id={'_status'}  value={_status} onChange={(e) => setStatus(e.target.value)}>
                        <option value={true}>เปิดใช้งาน</option>
                        <option value={false}>ปิดใช้งาน</option>
                    </select>
                </div>
                <div className='form-input'>
                    <span>ประเภท</span>
                    <select className='form-control' defaultValue={'ticket'} id={'_type'} value={_type} onChange={(e) => setType(e.target.value)}>
                        <option value={'ticket'}>ตั๋วแลกโค้ด</option>
                        <option value={'time'}>เพิ่มเวลาการใช้งาน</option>
                    </select>
                </div>
                <div className='form-input'>
                    <span>ราคา</span>
                    <input className='form-control' id={'_price'} type='number' value={_price} onChange={(e) => setPrice(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>ส่วนลด</span>
                    <input className='form-control' id={'_discount'} type='number' value={_discount} onChange={(e) => setDiscount(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>ลายระเอียด</span>
                    <input className='form-control' id={'_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)}></input>
                </div>
                <div className='form-input'>
                    <span>ไฟล์</span>
                    <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={UpdateCompany}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                    </div>                   
                </div> 
            </div>
        </div>
    )

}


export default EditProduct;