import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM  from 'react-dom';
import ModelServiceAccount from '../../classcomponent/modelServiceAccount';
import MainPage from '../../../Pages/MainPage';
import ServiceAccount from '../../../Pages/ServiceAccount';

function EditCodeSeviceAccount () {
    
    const [_member , setMember] = useState();
    const [_status , setStatus] = useState();
    const [_value , setValue] = useState();

    const UpdateCode = async (e) => {
        
        e.preventDefault();

        var _modelMember = null;
        var _modelStatus = null;
        var _modelValue = null;

        _member === undefined ? _modelMember = ModelServiceAccount.prototype.member : _modelMember = _member;
        _status === undefined ? _modelStatus = ModelServiceAccount.prototype.status : _modelStatus = _status;
        _value === undefined ? _modelValue = ModelServiceAccount.prototype.rolus : _modelValue = _value;
        
        _modelStatus === 'true' ? _modelStatus = true : _modelStatus = false;

        const _id =  ModelServiceAccount.prototype.id;
        const _properties = {description:_modelMember , status:_modelStatus , value:_modelValue };

        await _.db.doc(_id).update({
            properties:_properties
        });

        CloseFormEditDataToRelode();
    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataSeviceAccount');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataSeviceAccount');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<ServiceAccount/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditDataSeviceAccount' id='formEditDataSeviceAccount'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูล</h1>
                </div>

                <div className='form-input'>
                    <span>รหัส ID</span>
                    <input className='form-control' id={'_edUserID-s'} type='text' readOnly></input>
                </div>
                 <div className='form-input'>
                    <span>ช่องทางให้บริการ</span>
                    <input className='form-control' id={'_edvalue-s'} type='text'  onChange={(e) => setValue(e.target.value)}></input>
                   
                </div>
                <div className='form-input'>
                    <span>สถานะการใช้งาน</span>
                    <select className='form-control' id={'_edstatus-s'} onChange={(e) => setStatus(e.target.value)} >
                        <option value={true}>เปิดให้บริการ</option>
                        <option value={false}>ปิดให้บริการ</option>
                    </select>
                   
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_edmember-s'} type='text' onChange={(e) => setMember(e.target.value)}></input>
                   
                </div>
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateCode}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCodeSeviceAccount;