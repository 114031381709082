import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import MainPage from '../../../Pages/MainPage';
import CardsPage from '../../../Pages/CardsPage';
import _Cards from '../../classcomponent/modelsCards';


function CreateFormCards () {

    const [_img , setImg] = useState([]);
    const [_name , setName] = useState('');
    const [_status , setStatus] = useState(true);
    const [_rate , setRate] = useState('N');
    const [_percent , setPercent] = useState(0);
    const [_type , setType] = useState('card');
    const [_description , setDescription] = useState('');


    const formData = async (e) => {

        e.preventDefault();
    
        uploadFile();
    }

    const uploadFile = () => {

        _.storage.ref(`page/static/images/events/Songkran/cards/${_name}.png`).put(_img).then(()=>{
            AddNewCode();
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const AddNewCode = () => {

        const _path = `https://storage.googleapis.com/jarvis-24webservice.appspot.com/page/static/images/events/Songkran/cards/${_name}.png`;
      
        let _modelCards = new _Cards.ModelCards();

        _modelCards.name = _name;
        _modelCards.status = _status ? true : false;
        _modelCards.option.description = _description;
        _modelCards.option.rate = _rate;
        _modelCards.option.percent = _percent;
        _modelCards.option.type = _type;
        _modelCards.images.id = null;
        _modelCards.images.source = _path;
        _modelCards.created = _.timeStamp;

        _.cardsDB.add({

            name:_modelCards.name,
            status:_modelCards.status,
            option:{

                description:_modelCards.option.description,
                rate:_modelCards.option.rate,
                percent:parseInt(_modelCards.option.percent),
                type:_modelCards.option.type,

            },
            images:{

                id:`images-${_modelCards.name}`,
                source:_modelCards.images.source

            },
            created:_.timeStamp

        });

        _.imagesDB.doc(`images${_modelCards.name}`).set({

            name:_modelCards.name,
            source:_modelCards.images.source,
        })

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataCards');
        _form.style.top = '-100%';

        //ClearUseState();

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataCards');
        _form.style.top = '-100%';

        
        ClearUseState();
        refreshPage();

    }

    const ClearUseState = () => {

        setImg([])
        setName('');
        setStatus(true);
        setRate('R');
        setDescription('');

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<MainPage pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<MainPage pages={<CardsPage/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateDataCards' id='formCreateDataCards'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >เพิ่มการ์ด</h1>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อการ์ด</span>
                        <input className='form-control' id={'_name'} type='text' value={_name} onChange={(e) => setName(e.target.value)} placeholder={'ชื่อสินค้า'}></input>
                    </div>
                    <div className='form-input'>
                        <span>สถานะ</span>
                        <select className='form-control' defaultValue={true} id={'_status'}  value={_status} onChange={(e) => setStatus(e.target.value)}>
                            <option value={true}>เปิดใช้งาน</option>
                            <option value={false}>ปิดใช้งาน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ระดับ</span>
                        <select className='form-control' id={'_rate'} value={_rate} onChange={(e) => setRate(e.target.value)}>
                            <option value={'N'}>ไม่มี</option>
                            <option value={'R'}>ทั่วไป</option>
                            <option value={'SR'}>ยาก</option>
                            <option value={'SSR'}>หายาก</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ประเภทของสุ่ม</span>
                        <select className='form-control' id={'_type'} value={_type} onChange={(e) => setType(e.target.value)}>
                            <option value={'card'}>การ์ดสะสม</option>
                            <option value={'time'}>เพิ่มเวลา</option>
                            <option value={'bowl'}>ขันน้ำ</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>เปอร์เซ็นออก</span>
                        <input className='form-control' id={'_percent'} type='number' value={_percent} onChange={(e) => setPercent(e.target.value)} placeholder={'เปอร์เซ็นการสุ่มออก'}></input>
                    </div>
                    <div className='form-input'>
                        <span>ลายระเอียด</span>
                        <input className='form-control' id={'_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)} placeholder={'ลายละเอียด'}></input>
                    </div>
                    <div className='form-input'>
                        <span>ไฟล์</span>
                        <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                    </div>
                    <div className='form-input-btn'>

                        <div className='form-btn'>
                            <button className='btn btncreate' onClick={formData} type='submit' >ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                        </div>                   
                    </div>
                </div>
            </div>

        </form>
    )

}


export default CreateFormCards;