import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import _Cards from '../../classcomponent/modelsCards';

function DelCards () {

    const [_name , setName] = useState('');

    useEffect(() => {

        FachData();

    })

    const FachData = () => {

        setName(_Cards.ListCards.prototype.name);

    }
    
    const delData = async () => {


        const _modelID = _Cards.ListCards.prototype.id;

        const query = document.querySelector(`[id=data${_modelID}]`);
        query.remove();
        await _.cardsDB.doc(_modelID).delete();

        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataCards');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelDataCards' id='formDelDataCards'>
            <div className='box-data-search'>

                <div className='text-white'>
                    <h1 >ลบการ์ด</h1>
                </div>

                <div className='form-input'>
                    <span>ชื่อสินค้า</span>
                    <input className='form-control' id={'_delname-p'} type='text' value={_name} readOnly></input>
                </div>

                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelCards;